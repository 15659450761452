<template>
  <div class="dialog-wrap" v-show="paySubmitRed1">
    <div class="pay-submit">
      <img
        class="pay-submit-bg"
        src="https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/734/pay_submit_734.png"
      />
      <div class="content">
        <p v-for="(item, index) in pContent" :key="index">{{ item }}</p>
      </div>
      <div class="btn-wrap">
        <button @click="cancel">取 消</button>
        <button @click="submit">确 认</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    paySubmitRed1: Boolean,
  },
  computed: {
    ...mapGetters(['ruleJson']),
    pContent: function () {
      const { payContent } = this.ruleJson || {};
      return payContent || [];
    },
  },

  methods: {
    cancel() {
      this.$store.commit('order/SET_SHOWPAYSUBMIT', false);
    },
    submit() {
      this.$store.dispatch('order/pay');
    },
  },
};
</script>
<style lang="scss" scoped>
.pay-submit {
  width: 7.48rem;
  position: relative;

  .close {
    width: 1rem;
    position: absolute;
    bottom: -1.4rem;
    left: 3.25rem;
  }

  .content {
    width: 5rem;
    height: 2rem;
    color: #fff;
    text-align: justify;
    position: absolute;
    top: 2.5rem;
    left: 1.24rem;
    display: flex;
    align-items: center;
    font-size: 0.24rem;
  }

  .btn-wrap {
    width: 5rem;
    position: absolute;
    top: 4.58rem;
    left: 1.24rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      width: 2.36rem;
      height: 0.68rem;
      color: #fff;
      font-weight: 900;
      font-size: 0.3rem;
      padding: 0;
      background: url('https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/734/pay_btn_bg_734.png')
        no-repeat center/contain;
    }
  }

  .btn-wrap740 {
    top: 5.8rem;
  }
}
</style>
