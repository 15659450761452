<template>
  <div
    id="form-wrap"
    class="form-wrap"
    :class="{ formStep: formInfo.hasStep }"
    :style="{
      backgroundColor: formInfo.bgColor,
      width: (formInfo.width || 100) + '%',
      padding: (formInfo.padding || 10) + 'px',
    }"
  >
    <div class="form" v-if="!capFlag">
      <div class="item">
        <!-- 引导手势 -->
        <slot name="step1"></slot>

        <input
          type="tel"
          v-model="mobile"
          :disabled="codeTime != 60"
          maxlength="11"
          @input="inputReg('mobile')"
          placeholder="请输入手机号码"
        />
      </div>
      <!-- v-show="!orderStep.includes('UNDIRECT') || isSendCode" -->
      <div class="item code" v-show="!orderStep.includes('UNDIRECT') || isSendCode">
        <slot name="step2"></slot>
        <slot name="step3"></slot>

        <input
          type="tel"
          v-model="code"
          :maxlength="codeLength"
          @input="inputReg('code')"
          placeholder="请输入验证码"
        />
        <button :disabled="codeTime != 60" @click="sendCode">
          {{ codeContent }}
        </button>
      </div>
      <slot name="agree"></slot>

      <div ref="btn" class="btn" @click="submitBtn">
        <slot name="step4"></slot>
        <!-- 防止css动画影响手势引导 -->
        <div class="btn-inner" :class="{ 'btn-animate': formInfo.btnAnimate }">
          <template v-if="btnImgType == 'object'">
            <img
              v-for="(item, index) in formInfo.btnImg"
              :key="'btn' + index"
              :src="item"
              alt="按钮"
            />
          </template>

          <img v-else-if="formInfo.btnImg" :src="formInfo.btnImg" alt="按钮" />

          <p v-for="(item, index) in btnTextInfo" :key="'btn-text' + index" :style="item.style">
            {{ item.text }}
          </p>
        </div>
      </div>
    </div>

    <Cap
      v-if="capFlag == 1"
      :fsrc="fsrc"
      :hasValidate="hasValidate"
      :isCover="isCover"
      :origin="preOrderData.ascription"
    />
    <copy-Cap class="copy-cap" v-if="capFlag == 2" @close="closeCopyCap" />

    <Agree
      :hasAgreeRule="hasAgreeRule"
      :hasAgreePolicy="hasAgreePolicy"
      @show-policy="showPolicyHandle"
    ></Agree>

    <Intro></Intro>

    <Policy :show.sync="showPolicy" origin="woread"></Policy>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    codeFun: {
      type: Function,
      default: () => {},
    },
    byteMobile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mobile: '',
      code: '',
      reg: /^1(3|4|5|6|7|8|9)\d{9}$/,
      showPolicy: false,
    };
  },
  computed: {
    ...mapGetters([
      'preOrderData',
      'orderData',
      'params',
      'isSendCode',
      'codeTime',
      'codeContent',
      'capFlag',
      'fsrc',
      'hasValidate',
      'isCover',
      'curProdJson',
      'productId',
      'stepInfo',
    ]),
    formInfo: function () {
      return this.curProdJson.formInfo || {};
    },
    orderStep: function () {
      const { orderStep } = this.formInfo;
      return orderStep || '';
    },
    btnImgType: function () {
      return typeof this.formInfo.btnImg;
    },
    btnTextInfo: function () {
      const btnTextInfo = this.formInfo.textInfo || [];
      return btnTextInfo.filter((item) => !!item.text);
    },
    hasAgreeRule: function () {
      return this.productId != -1;
    },
    hasAgreePolicy: function () {
      return true;
    },
    codeLength: function () {
      const { captchaLength } = this.orderData || {};
      return captchaLength || 6;
    },
  },
  watch: {
    byteMobile: function (val) {
      this.mobile = val;
    },
    mobile: function (val) {
      if (val.length == 11 && this.params.actId) {
        // this.preOrder();
        this.$store.dispatch('order/preOrderHandle', {
          ...this.params,
          mobile: val,
        });
      }
    },
    code: function (val) {
      const { captchaLength } = this.orderData || {};
      if (captchaLength) {
        if (val.toString().length == captchaLength) {
          this.$store.dispatch('config/setCurrentStep', { currentStep: 'CLICK_BTN' });
        }
      } else if (val.toString().length == 4 || val.toString().length == 6) {
        this.$store.dispatch('config/setCurrentStep', { currentStep: 'CLICK_BTN' });
      }
    },
  },
  methods: {
    showPolicyHandle() {
      console.log(this.productId);
      console.log(this.showPolicy);
      this.showPolicy = true;
    },
    slotHasContent(slotName) {
      return this.$slots[slotName] && this.$slots[slotName].length > 0;
    },
    inputReg(v) {
      this[v] = this[v].replace(/[^0-9]/g, '');
    },
    preOrder() {
      if (!this.mobile) {
        this.$toast.faile('请输入手机号码');
        return;
      }
      if (!this.reg.test(this.mobile)) {
        this.$toast.faile('手机号码输入错误');
        return;
      }
      this.$store.dispatch('order/preOrder', {
        ...this.params,
        mobile: this.mobile,
      });
    },
    sendCode() {
      this.$store.dispatch('order/sendCode');
      // this.$store.dispatch('order/codeTime');
    },
    submitBtn() {
      if (!this.isSendCode) {
        this.$store.dispatch('order/preOrderHandle', {
          ...this.params,
          mobile: this.mobile,
        });
        return;
      }

      this.$store.dispatch('order/btnHandle', { code: this.code });
    },
    closeCopyCap() {
      console.log('x-Form:关闭');
    },
  },
  created() {},
  mounted() {
    this.mobile = this.$route.query.mobile || '';
  },
};
</script>

<style scoped lang="scss">
.form-wrap {
  margin: 0.3rem auto;
  border-radius: 10px;
  box-sizing: border-box;
  .form {
    margin-bottom: 0.2rem;
    position: relative;
    input {
      width: 100%;
      height: 100%;
      font-size: 0.36rem;
      color: #000;
      text-indent: 1em;
      background-color: transparent;
    }
    .step {
      position: absolute;
    }
    .item {
      height: 1rem;
      background: #ffffff;
      border-radius: 0.5rem;
      margin-bottom: 0.3rem;
      position: relative;
    }

    .code {
      // overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      input {
        // width: 50%;
        flex: 1;
      }
      button {
        width: 2rem;
        height: 100%;
        font-size: 0.3rem;
        color: #333;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        // background-color: gold;
      }
    }
    .btn {
      position: relative;
      p {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .step1 {
    color: red;
  }
}
</style>
