<template>
  <div class="success-container">
    <div class="banner">
      <Img src="/success/succ_banner.png" />
    </div>
    <h1>订单已提交</h1>

    <h3 v-if="pOrderData.productId == 202 || pOrderData.productId == 274">客服电话：4008089391</h3>

    <div v-if="pOrderData.productId == 530 || pOrderData.productId == 531" class="prod-banner">
      <img
        src="https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/success_prod_530.png"
        alt="素材"
      />
      <img
        class="btn btn-animate"
        src="https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/success_btn_530.png"
        alt="按钮"
        @click="toProdUrl"
      />
    </div>
    <div v-else class="ad-banner" @click="toAd">
      <img
        src="https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/ad/banner37.png"
        alt="素材"
      />
    </div>
  </div>
</template>

<script>
import { dotWL } from '@/api/dot';
import { mapGetters } from 'vuex';
import { getSessionObj } from '@/utils/storage';
import { dotVISIT } from '@/api/dot';
export default {
  name: '',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['params', 'preOrderData', 'productId']),
    pOrderData: function () {
      return this.preOrderData || getSessionObj('preOrderData') || {};
    },
  },
  methods: {
    toAd() {
      console.log('params', this.params);
      let baseUrl = 'https://zx.ruguobao.com.cn/czx/accident_1/jswlcd_rgyl88';
      dotWL({
        act: this.params?.actId,
        productName: this.pOrderData?.productName,
        chl: this.params?.chl,
        channel: this.params?.channel,
        actionIntro: '成功页点击广告',
        reqParams: JSON.stringify({ baseUrl }),
        queryKey: 'wl',
        queryValue: baseUrl,
      });
      window.location.href = baseUrl;
      // this.$store.dispatch("")
    },
    toProdUrl() {
      window.location.href = 'https://app.fmcc.com.cn/bass-broadbandZoneH5/newsubscribe?ch=hzww';
    },
  },
  created() {},
  mounted() {
    dotVISIT({
      act: this.params.actId,
      actionIntro: '访问成功页',
    });
  },
};
</script>

<style scoped lang="scss">
.success-container {
  padding-top: 1rem;
  .banner {
    width: 3rem;
    margin: 0 auto;
  }
  h1 {
    text-align: center;
    margin-top: 1rem;
    color: #f95171;
  }

  h3 {
    text-align: center;
    margin-top: 0.5rem;
  }

  .ad-banner {
    width: 6rem;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .prod-banner {
    width: 100%;
    position: relative;
    .btn {
      width: 3rem;
      position: absolute;
      bottom: 1rem;
      left: 2.3rem;
    }
  }
}
</style>
