<template>
  <div class="module">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {
    console.log('Module-Mounted');
  },
};
</script>

<style scoped lang="scss">
.module {
  position: relative;
}
.wel {
  width: 90%;
  margin: 0.3rem auto;
}
</style>
