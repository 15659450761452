<template>
  <div
    v-show="visible"
    class="scroll-wrap"
    ref="scroll-wrap"
    :style="{ '--swiperHeight': -swiperHeight * swiperList.length + 'rem' }"
  >
    <div class="cut-time">距离活动结束 {{ seconds }} : {{ microsecond }}</div>
    <div class="swiper-scroll" ref="scroll">
      <div ref="mobile-item" class="item" v-for="(item, index) in list" :key="index">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: '',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['curProdJson', 'codeTime']),
    topInfo: function () {
      return this.curProdJson.top || {};
    },
    swiperList: function () {
      return this[`${this.topInfo.mobileOp}List`] || this.cuccList;
    },
    list: function () {
      return this.swiperList.concat(this.swiperList.slice(0, 1));
    },
  },
  watch: {
    visible: {
      handler: function (v) {
        if (v) {
          setInterval(() => {
            this.cutTime -= 10;
            if (this.cutTime < 0) {
              this.cutTime = 60 * 1000;
              clearInterval();
            }
          }, 10);
        }
      },
      immediate: true,
    },
    cutTime: function (v) {
      this.seconds = Math.floor(v / 1000);

      if (this.cutTime <= 60 * 1000) {
        this.microsecond = (v % 1000) / 10;
      }
    },
  },

  data() {
    return {
      swiperHeight: 0.6,
      cuccList: [
        '155****0736抢到了',
        '186****7032抢到了',
        '131****0830抢到了',
        '176****4264抢到了',
        '186****2136抢到了',
        '186****6693抢到了',
        '155****1436抢到了',
        '186****3520抢到了',
      ],
      cmccList: [
        '138****0736抢到了',
        '158****7032抢到了',
        '139****0830抢到了',
        '138****4264抢到了',
        '188****2136抢到了',
        '134****6693抢到了',
        '137****1436抢到了',
        '152****3520抢到了',
      ],
      ctccList: [
        '180****5236抢到了',
        '181****3564抢到了',
        '177****3595抢到了',
        '189****8958抢到了',
        '153****5416抢到了',
        '180****0467抢到了',
        '199****0442抢到了',
        '133****1105抢到了',
      ],
      mixList: [
        '180****5236抢到了',
        '181****3564抢到了',
        '177****3595抢到了',
        '155****0736抢到了',
        '186****7032抢到了',
        '131****0830抢到了',
        '180****0467抢到了',
        '138****4264抢到了',
        '188****2136抢到了',
      ],

      cutTime: 61 * 1000,

      // 秒
      seconds: 0,
      // 微秒
      microsecond: '00',
    };
  },
  mounted() {
    const _this = this;
    // console.log(_this.$refs['scroll-wrap']);
    this.$nextTick(() => {
      // console.log(this.$refs["mobile-item"]);
      for (let index = 0; index < this.swiperList.length; index++) {
        const element = this.$refs['mobile-item'][index];
        this.swiperWidth = this.swiperWidth + element.offsetWidth + 20;
      }

      setTimeout(() => {
        _this.$refs['scroll'].setAttribute('id', 'animation');
        clearTimeout();
      }, 10);
      /* this.$refs["mobile-item"].forEach((item) => {
				console.log(item.offsetWidth);
				this.swiperWidth = this.swiperWidth + item.offsetWidth + 20;
			}); */
      // console.log(this.swiperWidth);
    });
  },
};
</script>

<style scoped lang="scss">
@keyframes scroll {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  100% {
    transform: translateY(var(--swiperHeight));
    -webkit-transform: translateY(var(--swiperHeight));
  }
}
.scroll-wrap {
  width: 7.5rem;
  height: 0.6rem;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;

  font-size: 0.24rem;
  color: #fff;
  .cut-time {
    flex: 1;
    height: 100%;
    line-height: 0.6rem;
    // text-align: center;
    text-indent: 1em;
  }
  .swiper-scroll {
    width: 4rem;
    // display: flex;
    // animation: scroll 10s linear infinite normal;
    .item {
      // flex-shrink: 0;
      overflow: hidden;
      padding: 0 30px;

      text-align: center;
      line-height: 0.6rem;
      border-radius: 0.3rem;
      & + .item {
        // margin-left: 20px;
      }
    }
  }
  #animation {
    animation: scroll 10s linear infinite normal;
  }
}
</style>
