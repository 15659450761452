import axios from 'axios';

/**
 * 获取活动说明
 * @param {*} actId
 * @returns
 */
export function getRuleApi(actId) {
  const act5Url =
    'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/json/' +
    actId +
    '.json?t=' +
    new Date().getTime();
  const actbbUrl =
    'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/json/' +
    actId +
    '.json?t=' +
    new Date().getTime();
  let firstUrl, secondUrl;
  if (typeof actId === 'string' && actId.includes('_')) {
    firstUrl = act5Url;
    secondUrl = actbbUrl;
  } else {
    firstUrl = actbbUrl;
    secondUrl = act5Url;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(firstUrl, { timeout: 10000 })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        window._apm('captureException', new Error(`firstUrl失败 ${e.toString()}`));
        axios
          .get(secondUrl, { timeout: 10000 })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            console.log(e);
            // reject(e);
            window._apm('captureException', new Error(`secondUrl失败 ${err.toString()}`));
            reject(err);
          })
          .finally(() => {
            reject(e);
          });
      });
  });
}

/**
 * 获取手机号省份信息
 * @param {*} number
 * @returns
 */
export async function getProvinceApi(number) {
  const res = await axios.post('https://province360.ilives.com.cn/?number=' + number);
  return res.data;
}

/**
 * 获取活动配置信息
 * @param {*} actId
 * @returns
 */
export function getConfigJson(actId) {
  return new Promise((resolve, reject) => {
    // https://mgcdn.oss-cn-hangzhou.aliyuncs.com/manage_resource/${actId}.json?t=${Date.now()}
    // https://mg2test.oss-cn-hangzhou.aliyuncs.com/manage_resource/200.json
    const BASE_URL = process.env.VUE_APP_OSS_URL;
    axios
      .get(`${BASE_URL}/manage_resource/${actId}.json?t=${Date.now()}`)
      .then((response) => {
        const res = response.data;
        resolve(res);
      })
      .catch((e) => reject(e));
  });
}

/**
 * 获取活动配置的定时任务
 * @returns
 */
export function getIntervalScheduleApi(actId) {
  return new Promise((resolve, reject) => {
    const BASE_URL = process.env.VUE_APP_OSS_URL;
    axios
      .get(`${BASE_URL}/manage_interval_resource/${actId}.json?t=${Date.now()}`)
      .then((response) => {
        const res = response.data;
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
