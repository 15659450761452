/* eslint-disable no-undef */
import { encryptAES } from '@/utils/AES';
import { createYdDot } from '@/utils/tools';

const state = {
  gdydActId237: [1000, 805, 810, 914, 902],
  gdydActId301: [917],
  gdydActId300: [806, 840, 947],
  gdydActId403: [645, 1230, 1200],
  initParams: {
    development: {
      debug: false,
      projectId: '99cc1de0c05764e5',
      ServerHost: 'uatcollect.gmcc.net',
      DatasourceId: 'bddc2ac92369174e',
      compress: false,
    },
    production: {
      debug: false,
      projectId: '8d2279a5e2f18b7c',
      ServerHost: 'collect.gmcc.net',
      DatasourceId: '9d59ac736f01c688',
      compress: false,
    },
  },
  gdydProdId: [
    {
      productId: 70,
      gdp_params: {
        goodsId_var: 'JYPT999.230110582645.0', // 业务办理时所属的商品ID。
        goodsName_var: '任我换铂金会员季度包 首月0.1元', //业务办理时商品名称。
        skuId_var: 'JYPT999.230110582645.0', // 业务办理时所属的skuID。
        skuName_var: '任我换铂金会员季度包 首月0.1元', //业务办理时sku名称。
        goodsType_var: '', //业务办理时商品类型。
        apiId_var: '', // 调用接口id
        apiName_var: '', // 调用接口名称。
        merchantsId_var: 'GDOPEN_2038813', // 商品所属商户id。
        merchants_var: '杭州牧尘科技有限公司', // 商品所属商户名称。
        processType_var: '页面浏览', // 业务办理时的每个办理步骤。（页面浏览、立即办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
        errorMessage_var: '', // 业务办理失败时的失败原因明细。（网络问题、业务互斥……）
        payType_var: '话费', // 业务办理支付时的支付方式名称。（支付宝、微信……）
        orderNumber_var: '', // 业务办理支付完成时所属订单号。
        ordertype_var: '新任我换订单', // 业务办理支付完成时所属订单类型名称。
        iop_tacticsId_var: '', // 页面访问时当前页面对应的IOP策略ID号。（物品模型）
        referFlowId_var: '', // 页面访问时当前页面运营位位置ID。（物品模型）
      },
    },
    {
      productId: 71,
      gdp_params: {
        goodsId_var: 'ZH20YRWH5ZYH10GB3', // 业务办理时所属的商品ID。
        goodsName_var: '新办理20元新任我换5折优惠送10GB流量（优惠3个月）', //业务办理时商品名称。
        skuId_var: 'ZH20YRWH5ZYH10GB3', // 业务办理时所属的skuID。
        skuName_var: '新办理20元新任我换5折优惠送10GB流量（优惠3个月）', //业务办理时sku名称。
        goodsType_var: '', //业务办理时商品类型。
        apiId_var: '', // 调用接口id
        apiName_var: '', // 调用接口名称。
        merchantsId_var: 'GDOPEN_2038813', // 商品所属商户id。
        merchants_var: '杭州牧尘科技有限公司', // 商品所属商户名称。
        processType_var: '页面浏览', // 业务办理时的每个办理步骤。（页面浏览、立即办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
        errorMessage_var: '', // 业务办理失败时的失败原因明细。（网络问题、业务互斥……）
        payType_var: '话费', // 业务办理支付时的支付方式名称。（支付宝、微信……）
        orderNumber_var: '', // 业务办理支付完成时所属订单号。
        ordertype_var: '新任我换订单', // 业务办理支付完成时所属订单类型名称。
        iop_tacticsId_var: '', // 页面访问时当前页面对应的IOP策略ID号。（物品模型）
        referFlowId_var: '', // 页面访问时当前页面运营位位置ID。（物品模型）
      },
    },
    {
      productId: 72,
      gdp_params: {
        goodsId_var: 'ZHXRWHJDB20', // 业务办理时所属的商品ID。
        goodsName_var: '20元任我换季度包（首月0.1元）', //业务办理时商品名称。
        skuId_var: 'ZHXRWHJDB20', // 业务办理时所属的skuID。
        skuName_var: '20元任我换季度包（首月0.1元）', //业务办理时sku名称。
        goodsType_var: '', //业务办理时商品类型。
        apiId_var: '', // 调用接口id
        apiName_var: '', // 调用接口名称。
        merchantsId_var: 'GDOPEN_2038813', // 商品所属商户id。
        merchants_var: '杭州牧尘科技有限公司', // 商品所属商户名称。
        processType_var: '页面浏览', // 业务办理时的每个办理步骤。（页面浏览、立即办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
        errorMessage_var: '', // 业务办理失败时的失败原因明细。（网络问题、业务互斥……）
        payType_var: '话费', // 业务办理支付时的支付方式名称。（支付宝、微信……）
        orderNumber_var: '', // 业务办理支付完成时所属订单号。
        ordertype_var: '新任我换订单', // 业务办理支付完成时所属订单类型名称。
        iop_tacticsId_var: '', // 页面访问时当前页面对应的IOP策略ID号。（物品模型）
        referFlowId_var: '', // 页面访问时当前页面运营位位置ID。（物品模型）
      },
    },
    {
      productId: 143,
      gdp_params: {
        goodsId_var: '20Y3GYB48YJ2G', // 业务办理时所属的商品ID。
        goodsName_var: '20元3G流量月包（前48个月每月加享2GB）', //业务办理时商品名称。
        skuId_var: '20Y3GYB48YJ2G', // 业务办理时所属的skuID。
        skuName_var: '20元3G流量月包（前48个月每月加享2GB）', //业务办理时sku名称。
        goodsType_var: '', //业务办理时商品类型。
        apiId_var: '', // 调用接口id
        apiName_var: '', // 调用接口名称。
        merchantsId_var: 'GDOPEN_2038813', // 商品所属商户id。
        merchants_var: '杭州牧尘科技有限公司', // 商品所属商户名称。
        processType_var: '页面浏览', // 业务办理时的每个办理步骤。（页面浏览、立即办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
        errorMessage_var: '', // 业务办理失败时的失败原因明细。（网络问题、业务互斥……）
        payType_var: '话费', // 业务办理支付时的支付方式名称。（支付宝、微信……）
        orderNumber_var: '', // 业务办理支付完成时所属订单号。
        ordertype_var: '流量月包订单', // 业务办理支付完成时所属订单类型名称。
        iop_tacticsId_var: '', // 页面访问时当前页面对应的IOP策略ID号。（物品模型）
        referFlowId_var: '', // 页面访问时当前页面运营位位置ID。（物品模型）
      },
    },
    {
      productId: 300,
      default: {
        goodsId_var: 'GS.prod.10086000061875',
        goodsName_var: '20元欢乐享礼包（A）',
      },
      gdp_params: {
        businessType_var: '存量', // 属于存量业务则写“存量”
        goodsId_var: 'GS.prod.10086000061875', // 业务办理时所属的商品ID。
        goodsName_var: '20元欢乐享礼包（A）', //业务办理时商品名称。
        goodsType_var: '新业务', //业务办理时商品类型。
        apiId_var: '', // 调用接口id
        apiName_var: '', // 调用接口名称。
        merchantsId_var: 'GDOPEN_2038813', // 商品所属商户id。
        merchants_var: '杭州牧尘科技有限公司', // 商品所属商户名称。
        merchantsIdCity_var: '省统',
        phoneNumber_var: '', //输⼊电话号码,传AES加密后的⼿机号
        processType_var: '页面浏览', // 业务办理时的每个办理步骤。（页面浏览、立即办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
        errorMessage_var: '', // 业务办理失败时的失败原因明细。（网络问题、业务互斥……）
        payType_var: '话费', // 业务办理支付时的支付方式名称。（支付宝、微信……）
        ordertype_var: '新业务订单', // 业务办理支付完成时所属订单类型名称。
      },
    },
    {
      productId: 237,
      default: {
        goodsId_var: 'prod.10086000049377',
        goodsName_var: '视频彩铃订阅-遨游流量包',
      },
      gdp_params: {
        businessType_var: '存量', // 属于存量业务则写“存量”
        goodsId_var: 'prod.10086000049377', // 业务办理时所属的商品ID。
        goodsName_var: '视频彩铃订阅-遨游流量包', //业务办理时商品名称。
        goodsType_var: '新业务', //业务办理时商品类型。
        apiId_var: '', // 调用接口id
        apiName_var: '', // 调用接口名称。
        merchantsId_var: 'GDOPEN_2038813', // 商品所属商户id。
        merchants_var: '杭州牧尘科技有限公司', // 商品所属商户名称。
        merchantsIdCity_var: '省统',
        phoneNumber_var: '', //输⼊电话号码,传AES加密后的⼿机号
        processType_var: '页面浏览', // 业务办理时的每个办理步骤。（页面浏览、立即办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
        errorMessage_var: '', // 业务办理失败时的失败原因明细。（网络问题、业务互斥……）
        payType_var: '话费', // 业务办理支付时的支付方式名称。（支付宝、微信……）
        ordertype_var: '新业务订单', // 业务办理支付完成时所属订单类型名称。
      },
    },
    {
      productId: 301,
      default: {
        goodsId_var: 'prod.10086000049377',
        goodsName_var: '视频彩铃订阅-遨游流量包',
      },
      gdp_params: {
        businessType_var: '存量', // 属于存量业务则写“存量”
        goodsId_var: 'prod.10086000049377', // 业务办理时所属的商品ID。
        goodsName_var: '视频彩铃订阅-遨游流量包', //业务办理时商品名称。
        goodsType_var: '新业务', //业务办理时商品类型。
        apiId_var: '', // 调用接口id
        apiName_var: '', // 调用接口名称。
        merchantsId_var: 'GDOPEN_2038813', // 商品所属商户id。
        merchants_var: '杭州牧尘科技有限公司', // 商品所属商户名称。
        merchantsIdCity_var: '省统',
        phoneNumber_var: '', //输⼊电话号码,传AES加密后的⼿机号
        processType_var: '页面浏览', // 业务办理时的每个办理步骤。（页面浏览、立即办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
        errorMessage_var: '', // 业务办理失败时的失败原因明细。（网络问题、业务互斥……）
        payType_var: '话费', // 业务办理支付时的支付方式名称。（支付宝、微信……）
        ordertype_var: '新业务订单', // 业务办理支付完成时所属订单类型名称。
      },
    },
    {
      productId: 403,
      default: {
        goodsId_var: 'GS.prod.10086000066481',
        goodsName_var: '20元欢乐享礼包',
      },
      gdp_params: {
        businessType_var: '存量', // 属于存量业务则写“存量”
        goodsId_var: 'GS.prod.10086000066481', // 业务办理时所属的商品ID。
        goodsName_var: '20元欢乐享礼包', //业务办理时商品名称。
        goodsType_var: '新业务', //业务办理时商品类型。
        apiId_var: '', // 调用接口id
        apiName_var: '', // 调用接口名称。
        merchantsId_var: 'GDOPEN_2038813', // 商品所属商户id。
        merchants_var: '杭州牧尘科技有限公司', // 商品所属商户名称。
        merchantsIdCity_var: '省统',
        phoneNumber_var: '', //输⼊电话号码,传AES加密后的⼿机号
        processType_var: '页面浏览', // 业务办理时的每个办理步骤。（页面浏览、立即办理、二次确认-确认、身份认证、二次确认-取消、提交订单、提交订单成功、提交订单失败、办理成功、办理失败、支付成功、支付失败）
        errorMessage_var: '', // 业务办理失败时的失败原因明细。（网络问题、业务互斥……）
        payType_var: '话费', // 业务办理支付时的支付方式名称。（支付宝、微信……）
        ordertype_var: '新业务订单', // 业务办理支付完成时所属订单类型名称。
      },
    },
  ],
};

const getters = {
  initAct: (state) =>
    state.gdydActId237
      .concat(state.gdydActId301)
      .concat(state.gdydActId300)
      .concat(state.gdydActId403),
};

const actions = {
  // 初始化
  init_gdyd({ state, getters, dispatch }, params) {
    // console.log(getters.initAct);
    const { actId } = params || {};
    const { initParams, gdydActId237, gdydActId301, gdydActId300, gdydActId403 } = state;
    const { initAct } = getters;
    const _initParams =
      process.env.NODE_ENV == 'production' ? initParams.production : initParams.development;
    try {
      if (initAct.includes(actId)) {
        createYdDot(
          window,
          document,
          'script',
          'https://assets.giocdn.com/sdk/webjs/cdp/gdp-full.js',
          'gdp',
        );
        /* gdp('init', _initParams.projectId, _initParams.DatasourceId, {
          ..._initParams,
          hashtag: true,
        }); */
        gdp('init', _initParams.projectId, _initParams.DatasourceId, {
          host: _initParams.ServerHost,
          compress: _initParams.compress,
          debug: _initParams.debug,
          hashtag: true,
        });
        gdp('setGeneralProps', {
          global_merchantsId_var: 'GDOPEN_2038813',
          global_merchants_var: '杭州牧尘科技有限公司',
          global_apiId_var: 'productorder_ckcommid',
          global_apiName_var: '商品办理(过滤黑名单)',
          global_merchantsIdCity_var: '省统',
        });
        if (gdydActId237.includes(actId)) {
          gdp('track', 'merchants_pageView', {
            pageName_var: '视频彩铃订阅-遨游流量包', // ⻚⾯访问时当前⻚⾯名称。
            merchantsId_var: 'GDOPEN_2038813', // 商品所属商⼾id。
            merchants_var: '杭州牧尘科技有限公司', // 商品所属商⼾名称。
            merchantsIdCity_var: '省统',
          });
          dispatch('track', { productId: 237, processType_var: '页面浏览' });
        }
        if (gdydActId403.includes(actId)) {
          gdp('track', 'merchants_pageView', {
            pageName_var: '20元欢乐享礼包', // ⻚⾯访问时当前⻚⾯名称。
            merchantsId_var: 'GDOPEN_2038813', // 商品所属商⼾id。
            merchants_var: '杭州牧尘科技有限公司', // 商品所属商⼾名称。
            merchantsIdCity_var: '省统',
          });
          dispatch('track', { productId: 403, processType_var: '页面浏览' });
        }
        if (gdydActId300.includes(actId)) {
          gdp('track', 'merchants_pageView', {
            pageName_var: '20元欢乐享礼包（A）', // ⻚⾯访问时当前⻚⾯名称。
            merchantsId_var: 'GDOPEN_2038813', // 商品所属商⼾id。
            merchants_var: '杭州牧尘科技有限公司', // 商品所属商⼾名称。
            merchantsIdCity_var: '省统',
          });
          dispatch('track', { productId: 300, processType_var: '页面浏览' });
        }
        if (gdydActId301.includes(actId)) {
          gdp('track', 'merchants_pageView', {
            pageName_var: '视频彩铃订阅-遨游流量包', // ⻚⾯访问时当前⻚⾯名称。
            merchantsId_var: 'GDOPEN_2038813', // 商品所属商⼾id。
            merchants_var: '杭州牧尘科技有限公司', // 商品所属商⼾名称。
            merchantsIdCity_var: '省统',
          });
          dispatch('track', { productId: 301, processType_var: '页面浏览' });
        }
      }
    } catch (error) {
      console.log(error);
    }
  },

  // 页面访问码
  track_view(store, params) {
    try {
      gdp(
        'track',
        'merchants_pageView',
        Object.assign(
          {
            pageName_var: '', // ⻚⾯访问时当前⻚⾯名称。
            merchantsId_var: 'GDOPEN_2038813', // 商品所属商⼾id。
            merchants_var: '杭州牧尘科技有限公司', // 商品所属商⼾名称。
            merchantsIdCity_var: '省统',
          },
          params,
        ),
      );
    } catch (error) {
      console.log(error);
    }
  },

  // 流程插码
  track({ state, rootGetters }, par) {
    const { params, preOrderData } = rootGetters;
    // console.log(preOrderData);
    const { mobile } = params || {};

    const { productId, processType_var, apiId_var, apiName_var, errorMessage_var } = par;
    // console.log(productId || preOrderData?.productId);
    const findGdydProd = state.gdydProdId.find(
      (item) => item.productId == (productId || preOrderData?.productId),
    );
    // console.log("findGdydProd-----", findGdydProd);
    const findGdpParams = findGdydProd ? findGdydProd.gdp_params : {};
    // console.log("findGdpParams------", findGdpParams);
    try {
      gdp('track', 'merchants_bussinessProcessing', {
        ...findGdpParams,
        apiId_var,
        apiName_var,
        phoneNumber_var: mobile ? encryptAES(mobile, '9e5702ead4d643fd') : '',
        processType_var,
        errorMessage_var,
      });
    } catch (error) {
      // console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,

  actions,
};
