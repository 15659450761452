<template>
  <div class="byte-phone-dialog-wrap" v-show="visible">
    <div
      class="byte-phone-dialog"
      :style="{
        backgroundColor: '#f24f44',
        paddingTop: '40px',
      }"
    >
      <div id="ydrzCustomControls"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss">
.byte-phone-dialog-wrap {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}
</style>
