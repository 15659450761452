<template>
  <div>
    <CopyCapShort v-if="!isLong" @close="close" />
    <CopyCapLong v-else @close="close" @pay="pay" />
  </div>
</template>

<script>
import CopyCapShort from './short.vue';
import CopyCapLong from './long.vue';

import { mapGetters } from 'vuex';
export default {
  name: '',
  components: { CopyCapShort, CopyCapLong },
  props: {
    capInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLong: true,
    };
  },
  computed: {
    ...mapGetters(['params', 'isProvincial', 'preOrderData']),
  },
  watch: {},
  methods: {
    sendCode() {
      this.$emit('sendCode');
    },
    pay(flag) {
      this.$emit('pay', flag);
      // console.log(flag);
    },
    close() {
      // console.log("中间层关闭");
      this.$emit('close');
    },
  },
  created() {
    this.isLong = this.preOrderData.ascription == '沃音乐' ? false : true;

    console.log(this.isLong);
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
};
</script>
<style scoped></style>
