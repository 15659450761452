import axios from 'axios';
import { getQueryParams } from '@/utils/tools.js';
/**
 * 生成UUID
 * @returns
 */
export function generateUUID() {
  if (localStorage.getItem('UUID')) {
    return localStorage.getItem('UUID');
  } else {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    console.log(uuid);
    localStorage.setItem('UUID', uuid.replace(/-/g, ''), 1);
    return uuid.replace(/-/g, '');
  }
}

/* {
    timestamp: time(),
    url: window.location.host,
    path: window.location.hash.split("#")[1],
    uid: generateUUID(),
    act: "活动编号",
    origin: "活动源",
    charge: "业务源",
    productName: "产品名称",
    chl: "渠道",
    channel: "通道",
    extra: "额外字段",
    action: "行为(VISIT,CLICK,ORDER,SUCCESS)",
    actionIntro: "行为描述",
    queryKey: "关键字",
    queryValue: "关键值",
    reqParams: "请求体(json字符串)",
    respParams: "响应体体(json字符串)",
} */
let requestUrl = 'https://dot.ilives.com.cn/act/recode';
export const reqparams = {
  timestamp: new Date().getTime(),
  url: window.location.host,
  path: window.location.href.split(window.location.origin)[1].split('?')[0],
  uid: generateUUID(),
  act: '',
  origin: '',
  charge: '',
  productName: '',
  chl: getQueryParams('chl') ? getQueryParams('chl') : '',
  channel: getQueryParams('channel') ? getQueryParams('channel') : '',
  extra: '',
  // queryKey: '',
  // queryValue: '',
  reqParams: '',
  respParams: '',
  isOp: 0,
  flag: 0,
  fUrl: '',
  fPath: '',
  fAct: '',
  fChl: '',
  fChannel: '',
  fExtra: '',
  // province: returnCitySN["cname"].split("省")[0] + "省",
  // city: returnCitySN["cname"].split("省")[1],
};
/**
 *  VISIT
 * @param {
 *    act: "活动编号",
 *    origin: "活动源",
 *    charge: "业务源",
 *    productName: "产品名称",
 *    chl: "渠道",
 *    channel: "通道",
 *    extra: "额外字段",
 *    actionIntro: "行为描述",
 *    queryKey: "关键字",
 *    queryValue: "关键值",
 *    reqParams: "请求体(json字符串)",
 *    respParams: "响应体体(json字符串)",
 * } params
 * @returns
 */
let obj = {};
if (window.location.href.includes('?')) {
  let params = window.location.href.split('?')[1];
  if (params.includes('#')) {
    params = params.split('#')[0];
  }

  params.split('&').forEach((item) => {
    obj[item.split('=')[0]] = item.split('=')[1];
  });
}

export function dotHANDLE(params, requestConfig) {
  // if (process.env.NODE_ENV == 'development') return;
  return new Promise((resolve, reject) => {
    const requestData = {
      ...reqparams,
      ...params,
    };
    const config = requestConfig || {};
    axios
      .post(requestUrl, requestData, config)
      .then((res) => resolve(res))
      .catch((err) => {
        window._apm('captureException', new Error(`dotHANDLE失败 ${err.toString()}`));
        reject(err);
      });
  });
}

export async function dotVISIT(params) {
  // if (process.env.NODE_ENV == 'development') return;
  return new Promise((resolve, reject) => {
    const requestData = {
      action: 'VISIT',
      actionIntro: '访问首页',
      ...reqparams,
      ...params,
      reqParams: params.reqParams
        ? JSON.stringify({ ...params.reqParams, ...obj })
        : JSON.stringify(obj),
    };

    const config = {};
    axios
      .post(requestUrl, requestData, config)
      .then((res) => resolve(res))
      .catch((err) => {
        window._apm('captureException', new Error(`dotVISIT失败 ${err.toString()}`));
        reject(err);
      });
  });
}

/**
 * CLICK
 * @param {*} params
 * @returns
 */
export async function dotCLICK(params) {
  // if (process.env.NODE_ENV == 'development') return;
  return new Promise((resolve, reject) => {
    const requestData = {
      action: 'CLICK',
      actionIntro: '',
      ...reqparams,
      ...params,
    };
    const config = {};
    axios
      .post(requestUrl, requestData, config)
      .then((res) => resolve(res))
      .catch((err) => {
        window._apm('captureException', new Error(`dotCLICK失败 ${err.toString()}`));
        reject(err);
      });
  });
}

/**
 * SUBMIT
 * @param {*} params
 * @returns
 */
export async function dotSUBMIT(params) {
  // if (process.env.NODE_ENV == 'development') return;
  return new Promise((resolve, reject) => {
    const requestData = {
      action: 'SUBMIT',
      actionIntro: '提交验证码',
      ...reqparams,
      ...params,
    };
    const config = {};
    axios
      .post(requestUrl, requestData, config)
      .then((res) => resolve(res))
      .catch((err) => {
        window._apm('captureException', new Error(`dotSUBMIT失败 ${err.toString()}`));
        reject(err);
      });
  });
}

/**
 * PREORDER
 * @param {*} params
 * @returns
 */
export async function dotPREORDER(params) {
  // if (process.env.NODE_ENV == 'development') return;
  return new Promise((resolve, reject) => {
    const requestData = {
      action: 'PREORDER',
      actionIntro: '',
      ...reqparams,
      ...params,
    };
    const config = {};
    axios
      .post(requestUrl, requestData, config)
      .then((res) => resolve(res))
      .catch((err) => {
        window._apm('captureException', new Error(`dotPREORDER失败 ${err.toString()}`));
        reject(err);
      });
  });
}

/**
 * CHANGE
 * @param {*} params
 * @returns
 */
export async function dotCHANGE(params) {
  // if (process.env.NODE_ENV == 'development') return;
  return new Promise((resolve, reject) => {
    const requestData = {
      action: 'CHANGE',
      actionIntro: '',
      ...reqparams,
      ...params,
    };
    const config = {};
    axios
      .post(requestUrl, requestData, config)
      .then((res) => resolve(res))
      .catch((err) => {
        window._apm('captureException', new Error(`dotCHANGE失败 ${err.toString()}`));
        reject(err);
      });
  });
}

/**
 * ORDER
 * @param {*} params
 * @returns
 */
export async function dotORDER(params) {
  if (process.env.NODE_ENV == 'development') return;
  return new Promise((resolve, reject) => {
    const requestData = {
      action: 'ORDER',
      actionIntro: '',
      ...reqparams,
      ...params,
    };
    const config = {};
    axios
      .post(requestUrl, requestData, config)
      .then((res) => resolve(res))
      .catch((err) => {
        window._apm('captureException', new Error(`dotORDER失败 ${err.toString()}`));
        reject(err);
      });
  });
}

/**
 * BACK
 * @param {*} params
 * @returns
 */
export function dotBACK(params) {
  if (process.env.NODE_ENV == 'development') return;
  return new Promise((resolve, reject) => {
    const requestData = {
      action: 'BACK',
      actionIntro: '',
      ...reqparams,
      ...params,
    };
    const config = {};
    axios
      .post(requestUrl, requestData, config)
      .then((res) => resolve(res))
      .catch((err) => {
        window._apm('captureException', new Error(`dotBACK失败 ${err.toString()}`));
        reject(err);
      });
  });
}

export function dotWL(params) {
  // if (process.env.NODE_ENV == 'development') return;
  return new Promise((resolve, reject) => {
    const requestData = {
      action: 'WL',
      actionIntro: '',
      ...reqparams,
      ...params,
    };
    const config = {};
    axios
      .post(requestUrl, requestData, config)
      .then((res) => resolve(res))
      .catch((err) => {
        window._apm('captureException', new Error(`dotWL失败 ${err.toString()}`));
        reject(err);
      });
  });
}

/**
 * ERROR
 * @param {*} params
 * @returns
 */
export function dotERROR(params) {
  if (process.env.NODE_ENV == 'development') return;
  axios
    .post(
      requestUrl,
      {
        action: 'ERROR',
        actionIntro: '',
        ...reqparams,
        ...params,
      },
      {},
    )
    .catch(() => {});
}
