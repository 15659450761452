<template>
  <div class="dialog-wrap" v-show="showPaySubmit">
    <div class="submit-main">
      <img :src="paySubmitBg" alt="" />
      <div class="content">
        <p v-for="(item, index) in pContent" :key="index">{{ item }}</p>
      </div>
      <div class="btn-group" :style="{ bottom: style.bottom }">
        <button
          class="cancel"
          :class="{ 'btn-text': !paySubmitCancelBtn.includes('http'), 'btn-bg': cancelBtnBg }"
          :style="
            paySubmitCancelBtn.includes('http') ? style.cancelImgStyle : style.cancelTextStyle
          "
          @click="cancelHandle"
        >
          <img v-if="paySubmitCancelBtn.includes('http')" :src="paySubmitCancelBtn" alt="" />
          <template v-else>{{ paySubmitCancelBtn }}</template>
        </button>
        <button
          class="submit btn-animate"
          :class="{ 'btn-text': !paySubmitSureBtn.includes('http'), 'btn-bg': submitBtnBg }"
          :style="paySubmitSureBtn.includes('http') ? style.sureImgStyle : style.sureTextStyle"
          @click="submitHandle"
        >
          <img v-if="paySubmitSureBtn.includes('http')" :src="paySubmitSureBtn" alt="" />
          <template v-else>{{ paySubmitSureBtn }}</template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: '',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['showPaySubmit', 'currentPaySubmitInfo', 'ruleJson']),
    paySubmitSureBtn() {
      const { paySubmitSureBtn } = this.currentPaySubmitInfo || {};
      return paySubmitSureBtn || '';
    },
    paySubmitCancelBtn() {
      const { paySubmitCancelBtn } = this.currentPaySubmitInfo || {};
      return paySubmitCancelBtn || '';
    },
    paySubmitBg() {
      const { paySubmitBg } = this.currentPaySubmitInfo || {};
      return paySubmitBg || '';
    },
    submitBtnBg() {
      const { submitBtnBg } = this.currentPaySubmitInfo || {};
      return submitBtnBg || '';
    },
    cancelBtnBg() {
      const { cancelBtnBg } = this.currentPaySubmitInfo || {};
      return cancelBtnBg || '';
    },
    style() {
      const { style } = this.currentPaySubmitInfo || {};
      return style || {};
    },
    pContent() {
      const { hasPayContent } = this.currentPaySubmitInfo || {};
      const { payContent } = this.ruleJson || {};
      return hasPayContent && (payContent || []);
    },
  },
  methods: {
    cancelHandle() {
      // this.$emit("cancel");
      this.$store.commit('order/SET_SHOWPAYSUBMIT', false);
    },
    submitHandle() {
      // this.$emit("submit");
      this.$store.dispatch('order/pay');
      // this.$store.dispatch("order/btnHandle", { code: this.code });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.submit-main {
  width: 100%;
  position: relative;
  .content {
    width: 5rem;
    height: 2rem;
    color: #fff;
    text-align: justify;
    position: absolute;
    top: 2.5rem;
    left: 1.24rem;
    display: flex;
    align-items: center;
    font-size: 0.24rem;
  }
  .btn-group {
    width: 100%;
    display: flex;
    justify-content: center;
    // align-items: flex-start;
    position: absolute;
    bottom: 0.2rem;
    left: 0;
    button {
      width: 2.4rem;
      & + button {
        margin-left: 0.3rem;
      }
    }
    .btn-text {
      font-size: 0.28rem;
      color: #fff;
    }
    .btn-bg {
      background: url('https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/734/pay_btn_bg_734.png')
        no-repeat center/contain;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}
</style>
