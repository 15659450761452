import CryptoJS from 'crypto-js';

/**
 * AES加密
 * @param {*} word      当前有效参数
 * @param {*} keyStr    KEY值，加密的盐
 * @returns
 */
export function encryptAES(word, keyStr) {
  // console.log('加密内容： word=',word);
  keyStr = keyStr ? keyStr : '1234567891234567'; //判断是否存在ksy，不存在就用定义好的key
  var key = CryptoJS.enc.Utf8.parse(keyStr);
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  // var res = encrypted.ciphertext.toString();
  var res = encrypted.toString();
  // console.log('解密结果： res=',res);
  return res;
}

/**
 * AES解密
 * @param {*} word      被AES加密后的字符串
 * @param {*} keyStr    KEY值，解密的盐
 * @returns
 */
export function decryptAES(word, keyStr) {
  // console.log('解密密文： word=',word);
  keyStr = keyStr ? keyStr : '1234567891234567';
  var key = CryptoJS.enc.Utf8.parse(keyStr);
  // let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  // let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.EBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  // var res = decrypt.toString(CryptoJS.enc.Utf8).toString();
  // console.log(decrypt)
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  // console.log('解密结果： res=',res);
  // return res;
}

/**
 * 随机生成指定数量的16进制key
 * @param {*} num   指定KEY的位数
 * @returns
 */
export function generatekeyAES(num) {
  let library = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let key = '';
  for (var i = 0; i < num; i++) {
    let randomPoz = Math.floor(Math.random() * library.length);
    key += library.substring(randomPoz, randomPoz + 1);
  }
  return key;
}
