<template>
  <div class="policyGdydWrap" v-if="show">
    <div class="policyGdyd">
      <div class="title">
        <h1>解约条款</h1>
        <img
          @click="closePolicy"
          src="https://sgact.oss-cn-hangzhou.aliyuncs.com/actStatic/img/circle-close.png"
          alt="关闭"
        />
      </div>
      <div class="policyGdydContent">
        甲方：中国移动通信客户<br />
        乙方：中国移动通信集团广东有限公司<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;甲方业务协议解约按类型分为“直接解约”和“有条件解约”两大场景。<span
          class="fontb"
          >所有涉及解约赔付需到指定的线下营业厅办理。</span
        ><br />
        <p class="font">一、直接解约</p>
        主要是涉及甲方直接订购的业务，乙方未进行任何优惠或补贴，甲方也未得从乙方得到实物或权益等相关的业务，采用无条件直接解约。<br />
        <p class="font">二、有条件解约</p>
        <p class="font" style="margin-top: 0">1.合约业务</p>
        如涉及赠送终端、话费存送、设备租赁或承诺在网或承诺消费获得相应优惠等业务，一般不能直接解约，甲方在“完成优惠赔付、不影响二次销售的退货”等情况下，有条件解约，按解约标准和流程操作（如有事先签订赔付标准，按约定的赔付标准执行）。具体如下：<br />
        <span class="font">（1）资金优惠：</span
        >如话费存送、套餐折扣等。解约条件：根据优惠、减免情况支付违约金。赔付标准：甲方如提前终止协议，则甲方需将已履行月份获赠金额或优惠减免金额总额、以及未履行月份所有低消或套餐金额总额的100%进行赔付。<br />
        <span class="font">（2）业务赠送：</span
        >如承诺消费送流量包、语音包、短信包等。解约条件：根据已赠送业务价值，支付合理的违约金。赔付标准：甲方如要求提前终止协议，则甲方需将已获赠业务价值的100%折算进行赔付。非流量类（语音、短信等）按单价标准资费100%折算，流量类按实际单价优惠资费100%折算。<br />
        <span class="font">（3）宽带业务：</span
        >如网龄送宽带，承诺低消送宽带。解约条件：提前终止宽带业务需对初装费或拆机费进行赔付。赔付标准：甲方如要求提前终止协议，需根据前期活动约定的初装费用或拆机费用进行赔付。<br />
        <span class="font">（4）实物赠送：</span
        >如承诺消费送终端、充值卡等。解约条件：在不影响二次销售的前提下，退还赠送的实物；在无法退还实物的情况下，通过对实物进行折现，支付违约金。赔付标准：根据退货要求且不影响二次销售的前提下，可在完成赠送实物退货后解约；如赠送实物已不满足退货条件，则甲方需根据实物赠送价值及未履行承诺期限进行补偿。<br />
        <span class="font">（5）权益赠送：</span
        >如咪咕视频、爱奇艺、优酷会员权益等。解约条件：需根据活动约定退回方可解约，不能退回需按价值折算进行补偿（权益赠送协议签订中应明确约定赠送权益的价值或折算方法）。赔付标准：权益未使用可在完成赠送权益回退后解约；权益已领取、使用，则甲方需将已使用权益进行价值折算，赔偿后解约。<br />
        <span class="font">（6）实物租赁：</span
        >如家庭宽带机顶盒、光猫等。解约条件：确保设备完好的情况下，返还租赁物；如无法归还或涉及其他利益损失的，可通过折现，支付合理违约金。赔付标准：甲方如要求提前终止协议，需根据活动约定条件退回或赔偿租赁物，根据退货约定且不影响二次销售的前提下，可在完成租赁实物退回后解约（注，如租赁物非新：在确保设备完好的情况下，可在完成租赁物退货后解约）；如租赁物已不满足再次租赁条件（设备已无法正常使用），则甲方需根据租赁设备价值进行等价偿还。<br />
        <p class="font">2.融合业务</p>
        融合套餐、主副卡、统付、共享、家庭网、集团网、惠农网、双向亲情互打等业务，需在不影响其他甲方业务使用的情况下，根据具体情况进行退订或解除。<br />
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <th>类别</th>
              <th>场景</th>
              <th>解约条件</th>
              <th>举例</th>
            </tr>
            <tr>
              <td rowspan="3">融合业务</td>
              <td>统付业务、主副卡等</td>
              <td>取消统付关系、解除相关成员</td>
              <td>家庭统付、家庭网、集团网等</td>
            </tr>
            <tr>
              <td>共享业务</td>
              <td>解除共享关系</td>
              <td>流量、语音共享等</td>
            </tr>
            <tr>
              <td>V网</td>
              <td>解除V网成员关联关系</td>
              <td>家庭网、集团网、惠农网、双向亲情互打等</td>
            </tr>
          </tbody>
        </table>
        注：①主卡、共享者解除，应对其他成员尽到通知义务。<br />
        ②副卡、被共享者解除，不应对其他成员权益造成损失。<br />
        <p class="font">3.吉祥号码业务</p>
        如四连号、三连号等吉祥号码。解约条件：按吉祥号码低消和未履行协议期进行赔付。赔付标准：如甲方要求提前终止协议，则甲方应当按照未履行月份所有低消或套餐金额总额向乙方支付损失赔偿金，如甲方有签约减免或降档低消金额特殊情况的，采用前述同样标准执行。（参考最新吉祥号码等级对应的低消或套餐标准）。<br />
        <p class="font">4.集团信息化业务</p>
        解约条件：根据乙方投入成本补贴和未履行时间等进行价值折算赔付（协议签订中应明确约定相关业务价值或折算方法）。<br />
        赔付标准：具体赔付标准按签订的协议条款折算。<br />
        <p class="font">三、业务退订</p>
        可选方式：可通过10086热线、线下营业厅或登录中国移动广东app等选择退订。<br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    closePolicy() {
      this.$emit('close', false);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.policyGdydWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  .policyGdyd {
    width: 7rem;
    max-height: 90%;
    background-color: #fff;
    border-radius: 0.2rem;
    padding-bottom: 0.2rem;
    display: flex;
    flex-direction: column;
    line-height: 2;
    .title {
      width: 100%;
      height: 1rem;
      text-align: center;

      line-height: 1rem;
      position: relative;
      h1 {
        font-size: 0.36rem;
      }
      img {
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
      }
    }
    .policyGdydContent {
      flex: 1;
      padding: 0.2rem;
      overflow-y: auto;
      .fontb {
        font-weight: 900;
      }
      .font {
        font-weight: bold;
        margin-top: 0.2rem;
      }
      table {
        border-right: 0.01rem solid #000;
        border-bottom: 0.01rem solid #000;
        th,
        td {
          border-top: 0.01rem solid #000;
          border-left: 0.01rem solid #000;
          text-align: center;
          padding: 0 0.05rem;
        }
      }
    }
  }
}
</style>
