<template>
  <div class="beian-content" :style="{ backgroundColor: bgColor }">
    <p class="companyName">{{ info.companyName }}</p>
    <p class="address">{{ info.address }}</p>
    <p class="hotMobile">{{ info.hotMobile }}</p>
    <p class="beianNum">{{ info.beianNum }}</p>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    bgColor: {
      type: String,
      default: '#9d0204',
    },
    host: {
      type: String,
      default: 'ky',
    },
  },
  data() {
    return {
      beianInfo: {
        xy: {
          companyName: '杭州翔羽科技有限公司',
          address: '浙江杭州滨江区信诚路555号1幢903室',
          hotMobile: '客服电话：4008089391',
          beianNum: '浙ICP备13025965号-1',
        },
        ky: {
          companyName: '杭州酷阅科技有限公司',
          address: '浙江杭州滨江区信诚路555号1幢903室',
          hotMobile: '客服电话：4008089391',
          beianNum: '浙ICP备2022006310号-1',
        },
        tbs: {
          companyName: '杭州土啵鼠网络科技有限公司',
          address: '浙江杭州滨江区信诚路555号1幢903室',
          hotMobile: '客服电话：4008089391',
          beianNum: '浙ICP备19025490号-1',
        },
        wwhy: {
          companyName: '杭州万物互云科技有限公司',
          address: '浙江杭州滨江区信诚路555号1幢903室',
          hotMobile: '客服电话：4008089391',
          beianNum: '浙ICP备2023008615号-1',
        },
      },
    };
  },
  computed: {
    info: function () {
      return this.beianInfo[this.host] || this.beianInfo['xy'];
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss">
.beian-content {
  // height: 2rem;
  padding: 0.4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: #fff;
    line-height: 1.5;
    font-size: 0.26rem;
  }
}
</style>
