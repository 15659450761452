<template>
  <div class="cap">
    <div class="header">
      <div class="cap_logo"><Img src="/logo/logo.png" /></div>
      <div class="title">{{ preOrderData.productName }}</div>
      <div class="zifei" v-show="isZifei">{{ preOrderData.postage / 100 }}元/月</div>
      <div class="notify">*本产品开通立即生效，次月自动续订，退订次月失效*</div>
    </div>
    <div class="form_title">填写信息</div>
    <!-- <slot></slot> -->
    <div class="cap_form">
      <div class="cap_input">
        <Img class="mobile_icon" src="/cap/cap_mobile.png" /><input
          :value="params.mobile"
          disabled
          type="text"
          placeholder="请输入手机号码"
        />
      </div>
      <div class="code">
        <div class="cap_input">
          <Img class="code_icon" src="/cap/cap_code.png" /><input
            type="text"
            v-model="pj.code"
            maxlength="6"
            @input="numValid"
            placeholder="输入验证码"
          />
        </div>
        <div class="cap_input code_btn" @click="sendCode">
          {{ codeContent }}
        </div>
      </div>
    </div>
    <div class="hot_line">客服电话:<i>10010</i></div>
    <div class="btn_group">
      <div class="cancel btn" @click="close">取消</div>
      <div class="submit btn" @click="pay">确认</div>
    </div>
  </div>
</template>

<script>
import { getCookie } from '@/utils/cookies';
import { mapGetters } from 'vuex';
// import { getProductInfo } from "@/api/tools";
// import { setCookie } from "../../utils/cookies";
export default {
  name: '',
  components: {},
  props: {
    mobile: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#ff0000',
    },
    isShow: {
      type: Boolean,
      default: true,
    },
    isZifei: {
      type: Boolean,
      default: true,
    },
    product: {
      type: Object,
      default: () => {
        return {
          productName: 'AR视频彩铃魔法会员15元包月',
          zifei: '15',
        };
      },
    },
  },
  data() {
    return {
      code: '',
      codeContent: '获取验证码',
      canSend: true,
      canPay: true,
      codeTimer: null,
      codeTime: 60,
      pj: {
        orderNo: '',
        code: '',
      },
      // reg
      reg: /^1(3|4|5|6|7|8|9)\d{9}$/,
    };
  },
  computed: {
    ...mapGetters(['params', 'isProvincial', 'preOrderData']),
  },
  watch: {},
  methods: {
    numValid() {
      this.pj.code = this.pj.code.replace(/[^0-9]/g, '');
    },
    async sendCode() {
      if (!this.canSend) {
        this.$toast('请稍后再试！');
        return;
      }
      this.canSend = false;

      this.$toast.loading('请稍后...');

      const res = await this.orderApi(this.params, this.preOrderData);
      console.log(res);

      if (res.code == 1) {
        this.countDown();
        // this.pj.orderNo = this.preOrderData.orderNo;
        this.pj.orderNo = res.data.orderNo;
      } else {
        this.$toast(res.msg);
        // this.close();
        setTimeout(() => {
          this.canSend = true;
          clearTimeout();
        }, 3000);
      }
    },
    // 倒计时
    countDown() {
      this.codeContent = '60s';
      this.codeTimer = setInterval(() => {
        this.codeTime--;
        this.codeContent = this.codeTime + 's';
        if (this.codeTime < 0) {
          clearInterval(this.codeTimer);
          this.codeContent = '获取验证码';
          this.codeTime = 60;
          this.canSend = true;
        }
      }, 1000);
    },
    async pay() {
      if (this.pj.code == '') {
        this.$toast('请输入验证码');
        return;
      }

      if (!this.canPay) {
        this.$toast('请稍后再试！');
        return;
      }
      this.canPay = false;

      this.$toast.loading('请稍后...');

      try {
        const res = await this.payApi(this.pj, {
          ...this.preOrderData,
          actId: this.params.actId,
        });

        if (res.code == 1) {
          // this.$router.push({ path: this.$route.path.split("index")[0] + "success", query: { productId: productId, successPage } });

          if (
            this.params.actId == 226 ||
            this.params.actId == 227 ||
            this.params.actId == 254 ||
            this.params.actId == 234 ||
            this.params.actId == 269 ||
            this.params.actId == 307 ||
            this.params.actId == 243 ||
            this.params.actId == 278 ||
            this.params.actId == 308
          ) {
            console.log('支付成功');
            this.$emit('pay', true);
            return;
          }

          this.$router.push({
            path: this.$route.path.split('index')[0] + 'success?instatus=3',
          });
        } else {
          this.$toast(res.msg);
          this.canPay = true;

          if (
            this.params.actId == 226 ||
            this.params.actId == 227 ||
            this.params.actId == 254 ||
            this.params.actId == 234 ||
            this.params.actId == 269 ||
            this.params.actId == 307 ||
            this.params.actId == 243 ||
            this.params.actId == 278 ||
            this.params.actId == 308
          ) {
            console.log('支付失败');
            this.$emit('pay', false);
          }
          /* window.clearInterval(this.codeTimer);
          this.time = 60;
          this.canSend = true;
          this.codeContent = "获取验证码";
          this.pj.code = ""; */
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    close() {
      window.clearInterval(this.codeTimer);
      this.time = 60;
      this.canSend = true;
      this.codeContent = '获取验证码';
      this.pj.code = '';
      console.log('关闭');
      this.$emit('close');
    },
  },
  async created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
};
</script>
<style scoped>
.cap {
  width: 7rem;
  /* height: 100%; */
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.2rem;
}
.cap .header {
  width: 94%;
  box-shadow: #f2f2f2 0.02rem 0.04rem 0.04rem 0.06rem;
  margin: 0 auto 0.3rem;
  padding: 0.08rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.1rem;
}
.cap .header .cap_logo {
  width: 2rem;
  padding-bottom: 0.08rem;
  /* box-sizing: border-box; */
}
.cap .header .title {
  color: #999;
  text-align: center;
  font-size: 0.24rem;
  /* font-size: 0.22rem; */
  line-height: 1.6;
  padding: 0.08rem 0;
  box-sizing: border-box;
}
.cap .header .zifei {
  color: #000;
  font-size: 0.32rem;
  line-height: 1.6;
  padding: 0.08rem 0;
}
.cap .header .notify {
  color: #999;
  font-size: 0.18rem;
  line-height: 1.6;
}
.cap .form_title {
  width: 94%;
  padding: 0.06rem 0.04rem;
  box-sizing: border-box;
  color: #333;
  font-size: 0.32rem;
  font-weight: 900;
  margin: 0 auto;
}
.cap .cap_form {
  padding: 0.05rem 0.2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.cap .cap_form .cap_input {
  /* width: 100%; */
  height: 1rem;
  box-shadow: #f2f2f2 0.02rem 0.04rem 0.04rem 0.06rem;
  padding: 0.1rem;
  padding-left: 0;
  box-sizing: border-box;
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
  border-radius: 0.1rem;
  font-size: 0.25rem;
}
.cap .cap_form .cap_input input {
  width: 80%;
  background: #fff;
  color: #555;
  -webkit-text-fill-color: #555;
  -webkit-opacity: 1;
  opacity: 1;
}
/* .cap .cap_form .input input[type="text"][disabled] {
  color: red;
} */
/* .cap .cap_form .input input::-webkit-input-placeholder {
  color: #999999;
} */
.cap .cap_form .cap_input .mobile_icon {
  width: 0.7rem;
  height: 0.75rem;
  padding-top: 0.1rem;
  margin: 0 !important;
}
.cap .cap_form .code {
  display: flex;
  justify-content: space-between;
}
.cap .cap_form .cap_input .code_icon {
  width: 0.7rem;
  height: 0.75rem;
  padding-top: 0.1rem;
}
.cap .cap_form .code .code_btn {
  width: 38%;
  font-size: 0.32rem;
  color: rgb(216, 0, 22);
  justify-content: center;
  margin-left: 0.2rem;
}
.cap .hot_line {
  width: 100%;
  text-align: center;
  color: #999;
  font-size: 0.24rem;
  margin: 0.1rem auto 0.2rem;
}
.cap .hot_line i {
  font-size: 0.22rem;
}
.btn_group {
  width: 94%;
  height: 1rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  /* padding: 0 0.08rem;

  box-sizing: border-box; */
}
.btn_group .btn {
  width: 50%;
  text-align: center;
  line-height: 1rem;
  font-size: 0.32rem;
  color: gray;
  box-sizing: border-box;
}
.btn_group .cancel {
  border: 1px solid gray;
  border-radius: 0.08rem 0 0 0.08rem;
}
.btn_group .submit {
  border: 1px solid rgb(216, 0, 22);
  border-radius: 0 0.08rem 0.08rem 0;
  background-color: rgb(216, 0, 22);
  color: #fff;
}
</style>
