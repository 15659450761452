import { nanoid } from 'nanoid';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';

export const APP_ID = `300012703523`;
export const APP_KEY = `A728BF1EF93A4D897DF458FFC6D12257`;
export const VERSION = '1.0';
export const BUSINESS_TYPE = '8';

export const getTime = () => dayjs().format('YYYYMMDDHHmmssSSS');
export const TIMESTAMP = getTime();

// 随机生成traceId
const getUniqueId = () => nanoid();
export const TRACE_ID = getUniqueId();

export const SIGN = CryptoJS.MD5(
  `${APP_ID}${BUSINESS_TYPE}${TRACE_ID}${TIMESTAMP}${TRACE_ID}${VERSION}${APP_KEY}`,
)
  .toString()
  .toUpperCase();

export const PRIVATE_KEY_STRING = `
    -----BEGIN PRIVATE KEY-----
    MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAL1vRDPz0+6jn7FT
    PKXyfhFLh2Vjdk9diV6W/eWYZftza6Yvazo+wmn+GmOXrcbpzCnHYKe+mJNQi1GS
    xS6lzrp2Uwqgn07aAopgy3AtdGNqM0ZNFxziOFUfsyZ//4eE9oYYf7odO43Qanwx
    +PFMMk//fyQS8EBP/Qmr+SkoVcJxAgMBAAECgYEAi6qAb4ljBPwwXi4Pdg5uclPs
    dI07ilJdWJcchO2yqwyzHqixdxUUg88sJ6eKITD5LQhps+NrXJmyzibXKIho/H40
    FGTamqTyBBX71CuHSeru3I5OLRSSdBA1RGLkv1uYokK9EveWoe9jrA7bahcc0OnF
    mH03ryv5HPfw0+ME9xECQQDhpw4KgdMlGeK7W4blBf1jJ3Va8Dt+UFpBPYocKgwx
    JZ7N2u1EPF5zEgXGDk3G0a7w5A1OIUv6gwC3vvENKGSlAkEA1ulFrf0Ptk3T4VlJ
    lx8wcklgtwKmWseshwXSmXoI6aGPajldzP+qFGMeusFjSgB75JVt3ZjwIQYV+1gI
    WYVg3QJAZRuKQhD7v9MCcbrIDZSKfcQvZEjiD24HktX1LTt17QuTRV/EEmAOXfcE
    WUMNfHl1roAwqgJnXd8XEj3fGmsv9QJADlEwES1AtLZunR6jpmuvgI2e4dJNXtLa
    jYw3wX29xkK7GSqFMzj9Daqky3BCuxVZK5tA3Hhp1ule4YW12LDoCQJAXyupOFVr
    pUeSOnA7jjbpmaVEeClFH7YvWOX9hcrfmXb6UCD29S8eHNPXdvwxHpY58lXCMBS9
    Z+LnlSMFCaJeNA==
    -----END PRIVATE KEY-----
  `;

export function getBytesToken({
  onSuccess = new Function(),
  onError = new Function(),
  returnStyleConfig = new Function(),
}) {
  const getTokenInfo = window.volcNumberAuthWebSdk.getTokenInfo;
  // console.log(getTokenInfo);
  const { styleConfig } = getTokenInfo({
    data: {
      version: VERSION,
      appId: APP_ID,
      // 加密后的sign（必填）。sign生成规则： MD5(appId + businessType + msgId + timestamp + traceId + version + appkey)
      sign: SIGN,
      traceId: TRACE_ID,
      timestamp: TIMESTAMP,
      authPageType: '2',
    },
    isUseDefaultStyle: true,
    styles: {
      layerStyle: {
        height: '260px',
      },
      submitBtnStyle: {
        high: '150px',
      },
    },
    onSuccess,
    onError,
  });
  returnStyleConfig(styleConfig);
}
