<template>
  <div class="cap" :style="capStyle">
    <iframe :src="fsrc" frameborder="0" width="100%" :style="iframeStyle"></iframe>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    origin: {
      type: String,
      default: 'CAP_A',
    },
    // cap长度
    length: {
      type: String,
      default: 'long',
    },
    // 是否有图验
    hasValidate: {
      type: Boolean,
      default: false,
    },
    // 是否遮挡
    isCover: {
      type: Boolean,
      default: false,
    },
    // 链接
    fsrc: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      capStyle: { height: '450px' },
      iframeStyle: { height: '100%', marginTop: '0' },
    };
  },
  computed: {},
  watch: {},
  methods: {
    style() {
      if (this.origin.includes('小沃') || this.origin.includes('沃阅读')) {
        // 沃阅读/小沃
        if (this.hasValidate && this.isCover) {
          // 有图验、遮挡
          this.capStyle.height = '330px';
          this.iframeStyle.marginTop = '-170px';
          this.iframeStyle.height = '500px';
        } else if (this.hasValidate && !this.isCover) {
          // 有图验、不遮挡
          // this.capStyle.height = "510px";
          this.capStyle.height = '550px';
          this.iframeStyle.marginTop = '0';
        } else if (!this.hasValidate && this.isCover) {
          // 无图验、遮挡
          this.capStyle.height = '290px';
          this.iframeStyle.marginTop = '-170px';
          this.iframeStyle.height = '450px';
        } else {
          // 无图验、不遮挡
          // this.capStyle.height = "450px";
          this.capStyle.height = '490px';
          this.iframeStyle.marginTop = '0';
        }
      }
      if (this.origin.includes('沃音乐')) {
        // 沃音乐
        if (this.hasValidate && this.isCover) {
          // 既有图验又遮挡
          this.capStyle.height = '250px';
          this.iframeStyle.marginTop = '-65px';
          this.iframeStyle.height = '400px';
        } else if (this.hasValidate && !this.isCover) {
          // 有图验无遮挡
          this.capStyle.height = '350px';
          this.iframeStyle.marginTop = '0';
          this.iframeStyle.height = '400px';
        } else if (!this.hasValidate && this.isCover) {
          // 无图验有遮挡
          this.capStyle.height = '300px';
          this.iframeStyle.marginTop = '-65px';
          this.iframeStyle.height = '400px';
        } else {
          // 无图验无遮挡
          this.capStyle.height = '300px';
          this.iframeStyle.marginTop = '0';
          this.iframeStyle.height = '400px';
        }
      }
    },
  },
  created() {},
  mounted() {
    this.style();
  },
  beforeCreate() {},
  beforeMount() {},
};
</script>
<style scoped lang="scss">
.cap {
  width: 7rem;
  max-width: 100%;
  border-radius: 0.1rem;
  overflow: hidden;
  margin: 0 auto;
  background-color: #fff;
  &::-webkit-scrollbar {
    display: none;
  }
  iframe {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  // background-color: #fff;
}
</style>
