<template>
  <div class="check" :style="introStyle">
    <div
      class="img"
      @click="toggleChecked"
      :style="{ borderColor: introStyle.color, color: introStyle.color }"
    >
      <span v-if="checked"></span>
    </div>
    <slot
      >我已详细阅读并同意<i v-if="hasAgreeRule" @click="showRule">订购须知、</i
      ><i v-if="hasAgreePolicy" @click="showPolicy">隐私条款</i
      ><template v-if="hasAgreePolicy && hasAgreeRule">等协议</template></slot
    >
  </div>
</template>

<script>
// import { pageScrollHandle } from '@/utils/tools';
import { mapGetters } from 'vuex';
export default {
  name: '',
  props: {
    hasAgreeRule: {
      type: Boolean,
      default: true,
    },
    hasAgreePolicy: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['curProdJson', 'checked', 'ipProductId', 'productId']),
    formInfo: function () {
      return this.curProdJson.formInfo;
    },
    introInfo: function () {
      const { introInfo } = this.formInfo || {};
      return introInfo || {};
    },
    introStyle: function () {
      const introInfo = this.introInfo || {};
      // eslint-disable-next-line no-unused-vars
      const { fontWeight, opacity, textDecoration, ...rest } = introInfo.style || {};
      return rest;
    },
  },
  watch: {},
  methods: {
    showPolicy() {
      this.$emit('show-policy', true);
    },
    showRule() {
      // pageScrollHandle(document.querySelector('#rule-wrap'), -20);
      this.$emit('show-rule', true);
      this.$store.commit('config/SET_RULEDIALOG', true);
    },
    toggleChecked() {
      this.$store.commit('order/SET_CHECKED', !this.checked);
      this.$emit('toggle-checked', true);
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
};
</script>
<style scoped>
.check {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* color: #fff; */
  font-size: 0.24rem;
  line-height: 1.5;
}
.check .img {
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.1rem;
  border: 1px solid var(--color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.02rem;
  box-sizing: border-box;
}
.check .img span {
  width: 100%;
  height: 100%;
  background-color: var(--color);
}
.check img {
  width: 0.3rem;
  display: inline-block;
}
.check i {
  text-decoration: underline;
  margin: 0 0.1rem;
}
</style>
