<template>
  <div class="intro" :style="introStyle">
    <!-- <p v-html="introInfo.text"></p> -->
    <p v-for="(item, index) in introText" :key="item + index">{{ item }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: '',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['curProdJson']),
    formInfo: function () {
      // console.log(this.curProdJson);
      return this.curProdJson.formInfo;
    },
    introInfo: function () {
      const { introInfo } = this.formInfo || {};
      // console.log(introInfo);
      return introInfo || {};
    },
    introText: function () {
      const { text } = this.introInfo || {};
      return text?.split('\n');
    },
    introStyle: function () {
      const introInfo = this.introInfo || {};
      const { marginTop, ...rest } = introInfo.style || {};
      return rest;
    },
  },
};
</script>
<style scoped lang="scss">
.intro {
  text-align: center;
}
</style>
