import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

export const routes = [
  {
    path: '/200/success',
    alias: ['/1093/9fkq21/success'],
    component: () => import('@/pages/success'),
  },
  // https://act2.ilives.com.cn/200/uK0bF5/index?chl=dsw&channel=wl
  // https://act6.tuborshu.com/1093/9fkq21/index?chl=dsw&channel=wl
  {
    path: '/200/uK0bF6/index',
    component: () => import('@/pages/template1'),
  },
  {
    path: '/wait',
    component: () => import('@/pages/wait'),
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || '/',
  routes,
});

export default router;
