<template>
  <div class="ad-wrap dialog-wrap" v-if="adModel">
    <div class="ad-content" v-for="(item, index) in selfAdList" :key="index" @click="toAd(item)">
      <img :src="item.adSrc" alt="" />
      <span><i>关闭广告</i><em @click.stop="closeAd">Ⅹ</em></span>
    </div>
  </div>
</template>

<script>
import { dotWL } from '@/api/dot';
import { mapGetters } from 'vuex';
export default {
  name: '',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['adModel', 'selfAdList']),
  },
  watch: {
    adModel: {
      handler: function (v) {
        if (v) this.backAd();
      },
    },
  },
  methods: {
    closeAd() {
      this.$store.commit('ad/SET_AD_MODEL', false);
    },
    toAd(ad) {
      const { adParams, adUrl } = ad || {};
      const { actionIntro, actId, chl, channel, redirectUrl } = adParams || {};
      try {
        dotWL({
          actionIntro: actionIntro ? actionIntro : '点击保险广告',
          act: actId,
          chl: chl,
          channel: channel,
          reqParams: JSON.stringify(adParams),
          queryKey: 'wl',
          queryValue: redirectUrl,
        });
      } catch (error) {
        console.log(error);
      }
      console.log(adUrl);

      if (adUrl) window.location.href = adUrl;

      this.closeAd();
    },
    backAd() {
      setTimeout(() => {
        this.toAd(this.selfAdList[0]);
        clearTimeout();
      }, 1000);
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.ad-wrap {
  .ad-content {
    width: 7.32rem;
    margin-bottom: 0.2rem;
    position: relative;
    span {
      color: #fff;
      font-size: 0.24rem;
      line-height: 1;
      position: absolute;
      top: 0.1rem;
      right: 0.2rem;
      i {
        font-size: 0.22rem;
        opacity: 0.8;
      }
    }
  }
}
</style>
