import { getCookie } from '@/utils/cookies';
import { createScript } from '@/utils/tools';
import { Base64 } from 'js-base64';
const state = {
  productList: [
    {
      productIds: [537],
      actIds: [],
      commonParams: {
        $TenantCode: 'HZXY', //渠道编码
        touchCode: 'bjhyjtll', // 产品小类编码
        businessLevel: 'lo0mEv18jW', // 产品编码
        businessType: 'WBQD', // 事件码（固定值：WBQD）
      },
      $biz_img:
        'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/shandong_long_1325.png',
    },
    {
      productIds: [554],
      actIds: [1200],
      commonParams: {
        $TenantCode: 'HZXY', //渠道编码
        touchCode: 'BJHY', // 产品小类编码
        businessLevel: 'WBQHPATLDm', // 产品编码
        businessType: 'WBQD', // 事件码（固定值：WBQD）
      },
      $biz_img:
        'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/img/shandong_long_1364.png',
    },
  ],
};
const getters = {
  hasSDK: (state, getters, rootState, rootGetters) => {
    const { productList } = state;
    const { params } = rootGetters;
    const { actId } = params || {};
    return productList.find((item) => item.actIds.includes(actId));
  },
  /* isAnalyticsTrack: (state, getters, rootState, rootGetters) => {
    const { productList } = state;
    const { preOrderData } = rootGetters;
    const { actId } = params || {};
    return productList.find((item) => item.actIds.includes(actId));
  }, */
};

const mutations = {};

const actions = {
  init({ getters, dispatch, rootGetters }) {
    const { hasSDK } = getters;
    if (!hasSDK) return;

    /* const { currentRoute, preOrderData } = rootGetters;
    const { path } = currentRoute || {};
    const { productId } = preOrderData || {}; */

    window.xwCodeDefaults = {
      domain: 'https://m.sd.10086.cn/sd_collector_service/',
      apiKey: '9FD8C6EE6FA972068BFBA9129D440457',
    };
    createScript(
      'https://sgact.oss-cn-hangzhou.aliyuncs.com/actbb2/static/js/analytics.min.js',
    ).then(() => {
      dispatch('track', { steps: 20, productId: 554 });
      /* if (path.includes('success')) {
        dispatch('track', { steps: 99, mobile: getCookie('mobile') });
      } */
    });
  },
  track({ state, rootGetters }, stepParams) {
    /* const { isAnalyticsTrack } = getters;
    if (!isAnalyticsTrack) return; */

    const { params } = rootGetters;
    const { mobile } = params;

    const { productId, ...rest } = stepParams;
    const { productList } = state;

    const findProduct = productList.find((item) => item.productIds.includes(productId));
    if (!findProduct) return;
    const { commonParams, $biz_img } = findProduct;

    const trackParams = {
      ...commonParams,
      ...rest,
    };
    trackParams.userMobile = mobile && Base64.encode(mobile);
    stepParams.steps == 20 && (trackParams.$biz_img = $biz_img);

    window.analytics.track('click', trackParams);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
