<template>
  <div class="rule-dialog-container" v-show="showRuleDialog">
    <div class="rule-dialog">
      <div class="rule-title">{{ title }} <span @click="closeRuleDialog">×</span></div>
      <div class="rule-dialog-content">
        <Rule :hasTitle="false" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '订购须知',
    },
  },
  computed: {
    ...mapGetters(['showRuleDialog']),
  },
  methods: {
    closeRuleDialog() {
      // this.$emit('update:visible', false);
      this.$store.commit('config/SET_RULEDIALOG', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.rule-dialog-container {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .rule-dialog {
    width: 85%;
    max-height: 80%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 0.2rem;
    .rule-title {
      height: 1rem;
      font-size: 0.5rem;
      text-align: center;
      border-bottom: 1px solid #ccc;
      line-height: 1rem;
      position: relative;
      span {
        font-size: 0.6rem;
        position: absolute;
        top: 0;
        right: 0.3rem;
      }
    }
    .rule-dialog-content {
      flex: 1;
      overflow-y: auto;
      padding-top: 0.3rem;
      button {
        width: 100%;
        height: 0.8rem;
        font-size: 0.3rem;
        border: 1px solid #ccc;
      }
    }
  }
}
</style>
