<template>
  <div class="policyGdltWrap" v-if="show">
    <div class="policyGdlt">
      <img
        @click="closePolicy"
        src="https://sgact.oss-cn-hangzhou.aliyuncs.com/actStatic/img/circle-close.png"
        alt="关闭"
      />
      <div class="title">
        <h1>《关于客户个人信息收集、使用及保护的公告》</h1>
      </div>
      <div class="policyGdydContent">
        <p class="p">尊敬的客户：</p>
        <p class="p">您好！</p>
        <p class="p">
          根据《中华人民共和国网络安全法》《全国人民代表大会常务委员会关于加强网络信息保护的决定》《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。您在使用套餐类业务办理、通用流量包类业务办理、定向流量包类业务办理、权益+流量包办理、语音短信等这类业务办理服务时，我公司可能需要收集和使用您的手机号码、订购短信验证码，确保成功为您办理业务。
        </p>
        <p class="p">
          经过您的授权同意后，在符合相关法律法规与隐私政策的情况下，上述信息会提供给我公司的合作运营商中国联合网络通信有限公司（以下简称“中国联通”），用于为您办理业务。中国联通隐私政策的详细内容请点击此处查询<a
            href="https://docs.qq.com/doc/DTVJGUlVZTU10ZUFV"
            target="_blank"
            >https://docs.qq.com/doc/DTVJGUlVZTU10ZUFV</a
          >。
        </p>
        <p class="p">
          为向客户提供优质、个性化的服务，我公司将遵循合法、正当、必要与诚信的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
        </p>
        <p>（1）事先获得客户的明确授权；</p>
        <p>（2）根据有关的法律法规要求；</p>
        <p>（3）按照相关司法机关和/或政府主管部门的要求；</p>
        <p>（4）为维护社会公众的利益所必需且适当；</p>
        <p>（5）为维护我公司或客户的合法权益所必需且适当；</p>
        <p>
          （6）为确保您能成功办理中国联通独立或与我们合作的运营活动，我们会经过您的单独同意后，将您提交的信息提供给中国联通。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: '',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['showPolicyGdlt']),
  },
  methods: {
    closePolicy() {
      // this.$store.commit('config/SET_GDLTPOLICY', false);
      this.$emit('close', false);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.policyGdltWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  .policyGdlt {
    width: 7rem;
    max-height: 90%;
    background-color: #fff;
    border-radius: 0.2rem;
    padding-bottom: 0.2rem;
    display: flex;
    flex-direction: column;
    line-height: 2;
    position: relative;
    padding-top: 1rem;
    box-sizing: border-box;
    .title {
      width: 100%;
      height: 1rem;
      text-align: center;
      line-height: 1rem;
      h1 {
        font-size: 0.3rem;
      }
    }
    img {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
    }
    .policyGdydContent {
      flex: 1;
      padding: 0.2rem;
      overflow-y: auto;
      text-align: justify;
      .p {
        text-indent: 2em;
        a {
          text-decoration: underline;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
