import Cookies from 'js-cookie';

export function getCookie(key) {
  return Cookies.get(key);
}

export function setCookie(key, value, time = 1) {
  return Cookies.set(key, value, { expires: time });
}

export function removeCookie(key) {
  return Cookies.remove(key);
}
