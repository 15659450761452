<template>
  <div id="rule-wrap" class="rule-wrap">
    <div class="rule-title" v-if="hasTitle && ruleJson.rule">☛ 订购须知 ☚</div>
    <div class="rule">
      <div v-if="ruleType == 'string'" v-html="ruleJson.rule"></div>
      <div v-else>
        <ul v-for="(item, index) in ruleJson.rule" :key="index">
          <li class="title">{{ item.title }}</li>
          <li v-for="(ite, index) in item.content" :key="index" v-html="ite"></li>
        </ul>
      </div>
    </div>
    <slot name="rule-bottom"></slot>

    <div class="rule" v-if="params.channel == 'tx'">
      <ul>
        <br />
        <li>订购须知</li>
        <li>
          1、本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉！
        </li>
        <li>
          2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: '',
  props: {
    hasTitle: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['curProdJson', 'ruleJson', 'params']),
    ruleInfo: function () {
      return this.curProdJson.rule || {};
    },
    ruleType: function () {
      return typeof this.ruleJson.rule;
    },
  },
  watch: {
    'ruleInfo.contentType': {
      handler: function (val) {
        // 1：图片   2：文字
        if (val == 1) {
          // console.log(this.ruleInfo.)
        }
      },
      immediate: true,
    },
    'ruleInfo.ruleActId': {
      handler: function (v) {
        if (v) {
          this.$store.dispatch('config/getActRuleInfo', { actId: v });
        }
      },
      // immediate: true,
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.rule-title {
  font-size: 0.4rem;
  text-align: center;
  margin: 0.3rem auto;
}
.rule::v-deep {
  border-radius: 0.18rem;
  padding: 0.32rem;
  padding-top: 0;
  box-sizing: border-box;
  p {
    font-size: 0.26rem;
    // font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 0.48rem;
    text-align: justify;
    word-break: break-all;
    a {
      color: inherit;
      font-weight: 900;
      text-decoration: underline;
    }
  }
  ul {
    li {
      font-size: 0.26rem;
      font-weight: 500;
      line-height: 0.48rem;
      text-align: justify;
      word-break: break-all;
      a {
        color: inherit;
        font-weight: 900;
        text-decoration: underline;
      }
    }
    .title {
      font-size: 0.3rem;
      font-weight: 900;
    }
    & + ul {
      margin-top: 0.3rem;
    }
  }
}
</style>
