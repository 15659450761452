<template>
  <div class="policy-wrap" v-if="show">
    <div class="policy">
      <div class="title">隐私政策<i @click="close">×</i></div>
      <div class="content" v-html="html"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: '',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    origin: '',
  },
  data() {
    return {
      html: '',
    };
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
  },
  async created() {
    let baseUrl = 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/json/policy';

    let url = this.origin ? baseUrl + '-' + this.origin + '.json' : '';

    const res = await axios.get(url + '?t=' + new Date().getTime());
    // console.log(res);
    // console.log(res.data.content.join());
    this.html = res.data.content.join().replaceAll(',', '');
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.policy-wrap {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  padding: 0.3rem;
  box-sizing: border-box;
}
.policy ::v-deep {
  width: 100%;
  height: 100%;
  font-size: 0.26rem;
  line-height: 0.4rem;
  padding: 0.2rem;
  box-sizing: border-box;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 0.1rem;
  .title {
    font-size: 0.4rem;
    text-align: center;
    line-height: 1rem;
    position: relative;
    i {
      font-size: 0.5rem;
      position: absolute;
      right: 0;
    }
  }
  p {
    margin: 0.16rem 0;
    text-align: justify;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
}
</style>
