<template>
  <div
    class="page-container"
    :style="{
      '--bgColor': curProdJson.bgColor,
      '--color': curProdJson.color || '#fff',
      backgroundColor: curProdJson.bgColor,
      color: curProdJson.color || '#fff',
    }"
  >
    <Module>
      <div class="img-list">
        <img v-for="(item, index) in topInfo.topImg" :key="index" :src="item" alt="" />
        <p
          v-for="(item, index) in topTextInfo"
          :key="item.text + index"
          :style="{
            width: item.style.marginLeft ? 'auto' : '100%',
            fontSize: item.style.fontSize,
            color: item.style.color,
            top: item.style.marginTop,
            left: item.style.marginLeft,
            opacity: item.style.opacity,
            textDecoration: item.style.textDecoration,
            fontWeight: item.style.fontWeight,
          }"
        >
          <template v-if="item.show">{{ item.text }}</template>
        </p>
      </div>
    </Module>

    <MobileSwiper></MobileSwiper>

    <div v-if="createFormByte" v-show="byteFormVisible" id="ydrzCustomControls"></div>

    <x-Form v-show="hasLoadConfig && !byteFormVisible" :byteMobile="byteMobile" ref="xForm">
      <template>
        <div
          v-for="item in stepInfo"
          :key="item.name"
          :slot="item.name"
          class="step"
          :style="item.style"
        >
          <img v-if="currentStep == item.stepName" :src="item.img" alt="" />
        </div>
      </template>
    </x-Form>

    <Module class="wel" v-if="productId != -1">
      <div class="img-list">
        <img v-for="(item, index) in welInfo.welImg || []" :key="index" :src="item" alt="" />
      </div>
    </Module>
    <!-- v-if="
        productId != -1 ||
        params.actId == 1208 ||
        params.actId == 1247 ||
        params.actId == 1238 ||
        params.actId == 1265
      " -->
    <!-- v-if="visibleRule" -->
    <Rule v-if="visibleRule" />

    <!-- 一键取号弹窗 -->
    <ByteMobile v-if="createDialogByte" :visible.sync="byteDialogVisible" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ByteMobile from '@/components/ByteMobile';
import { dotVISIT } from '@/api/dot';

export default {
  components: { ByteMobile },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'curProdJson',
      'ipProductId',
      'productId',
      'hasLoadConfig',
      'params',
      'currentRoute',
      'stepInfo',
      'currentStep',
      'byteDialogVisible',
      'byteFormVisible',
      'byteMobile',
      'createFormByte',
      'createDialogByte',
    ]),
    topInfo: function () {
      return this.curProdJson.top || {};
    },
    welInfo: function () {
      return this.curProdJson.wel || {};
    },
    topTextInfo: function () {
      const topTextInfo = this.topInfo.textInfo || [];
      return topTextInfo.filter((item) => !!item.text);
    },
    visibleRule: function () {
      let flag;
      const { rule } = this.curProdJson || {};
      const { visibleRule } = rule || {};
      if (this.productId == -1) {
        if (visibleRule) {
          if (visibleRule == 'VISIBLE') {
            flag = true;
          } else {
            flag = false;
          }
        } else {
          flag = false;
        }
      } else {
        return true;
      }
      return flag;
    },
  },

  methods: {
    testHandle() {
      this.$router.push({ path: this.currentRoute.path.replace('index', 'success') });
    },
  },
  mounted() {
    console.log('Template-Mounted');
    localStorage.setItem('PageLoadFullTime', Date.now());
    console.log('PageLoadFullTime', Date.now());

    this.$store.dispatch('byte_mobile/getBytesTokenHandle');

    dotVISIT({
      act: this.params.actId,
      actionIntro: '访问首页',
    });
  },
};
</script>

<style scoped lang="scss">
.page-container {
  // min-height: 100vh;
  .module {
    p {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
    }
  }
}
</style>
