<template>
  <img
    v-if="lazy"
    :style="{ width }"
    v-lazy="src ? 'https://image.ilives.com.cn/act5/static/img' + src : ''"
    alt=""
  />
  <img
    v-else
    :style="{ width }"
    :src="src ? 'https://image.ilives.com.cn/act5/static/img' + src : ''"
    alt=""
  />
  <!-- :src="src ? 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img' + src : ''" -->
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
};
</script>
<style scoped>
img {
  margin: 0 auto;
}
</style>
