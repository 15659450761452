import { mapObject, pxToRem, getJson, isInTime } from '@/utils/tools';
import { preOrderForIPApi } from '@/api';
import { getRuleApi, getIntervalScheduleApi, getConfigJson } from '@/api/tools';
import { dotCHANGE } from '@/api/dot';

const state = {
  hasLoadConfig: false,

  currentRoute: null,
  unIpLoadAct: [
    1038, 1028, 1029, 1057, 746, 801, 904, 1067, 747, 1014, 1065, 1066, 1071, 1074, 1058,
  ],
  // ip加载页面，例外省份
  unIpLoadActExceptProvince: ['四川'],

  showPolicyGdlt: false,

  showRuleDialog: false,

  ruleJson: '',

  confingPageInfo: null,
  configActJson: null,
  configProduct: null,

  intervalScheduleList: [],

  ipProductInfo: null,

  currentStep: 'INPUT_MOBILE',
  actStepInfo: [
    {
      actIds: [
        1244, 1265, 1269, 1270, 1283, 1310, 1309, 1323, 1344, 1352, 1353, 1361, 1374, 1372, 1373,
        1378, 1379, 1380, 1366, 1367, 1368,
      ],
      stepScheme: '1',
      step: [
        {
          name: 'step1',
          stepName: 'INPUT_MOBILE',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form1_step1.gif',
          style: { width: '4rem', bottom: '0.8rem', left: '0.5rem' },
        },
        {
          name: 'step2',
          stepName: 'CLICK_CODE',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form1_step2.gif',
          style: { width: '0.7rem', bottom: '0.8rem', right: '0.5rem' },
        },
        {
          name: 'step3',
          stepName: 'INPUT_CODE',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form1_step3.gif',
          style: { width: '4rem', bottom: '0.7rem', left: '0.5rem' },
        },
        {
          name: 'step4',
          stepName: 'CLICK_BTN',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form1_step4.gif',
          style: { width: '4rem', top: '0.8rem', left: '0.5rem', zIndex: 1 },
        },
      ],
    },
    {
      actIds: [1247, 1281, 1286, 1290, 1300, 1306, 1313, 1327, 1333],
      stepScheme: '2',
      step: [
        {
          name: 'step1',
          stepName: 'INPUT_MOBILE',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form2_step1.gif',
          style: { width: '4rem', bottom: '0.8rem', left: '0.5rem' },
        },
        {
          name: 'step2',
          stepName: 'CLICK_CODE',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form2_step2.gif',
          style: { width: '1.4rem', bottom: '0.8rem', right: '0.2rem' },
        },
        {
          name: 'step3',
          stepName: 'INPUT_CODE',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form2_step3.gif',
          style: { width: '4rem', bottom: '0.7rem', left: '0.5rem' },
        },
        {
          name: 'step4',
          stepName: 'CLICK_BTN',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form2_step4.gif',
          style: { width: '4rem', top: '0.8rem', left: '0.5rem', zIndex: 1 },
        },
      ],
    },
    {
      actIds: [1315],
      stepScheme: '3',
      step: [
        {
          name: 'step1',
          stepName: 'INPUT_MOBILE',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form3_step1.gif',
          style: { width: '4rem', bottom: '0.8rem', left: '0.5rem' },
        },
        {
          name: 'step2',
          stepName: 'CLICK_CODE',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form2_step2.gif',
          style: { width: '1.4rem', bottom: '0.8rem', right: '0.2rem' },
        },
        {
          name: 'step3',
          stepName: 'INPUT_CODE',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form2_step3.gif',
          style: { width: '4rem', bottom: '0.7rem', left: '0.5rem' },
        },
        {
          name: 'step4',
          stepName: 'CLICK_BTN',
          img: 'https://sgact.oss-cn-hangzhou.aliyuncs.com/act5/static/img/form_step/form3_step4.gif',
          style: { width: '4rem', top: '0.8rem', left: '0.5rem', zIndex: 1 },
        },
      ],
    },
  ],
};

const mutations = {
  SET_HASLOAD_CONFIG: (state, hasLoadConfig) => {
    state.hasLoadConfig = hasLoadConfig;
  },
  SET_CURRENT_ROUTE: (state, route) => {
    state.currentRoute = route;
  },
  SET_GDLTPOLICY: (state, showPolicyGdlt) => {
    state.showPolicyGdlt = showPolicyGdlt;
  },
  SET_RULEDIALOG: (state, showRuleDialog) => {
    state.showRuleDialog = showRuleDialog;
  },
  SET_RULEJSON: (state, ruleJson) => {
    state.ruleJson = ruleJson;
  },
  SET_CONFIG_PAGE_INFO: (state, confingPageInfo) => {
    state.confingPageInfo = confingPageInfo;
  },
  SET_CONFIG_ACT_JSON: (state, configActJson) => {
    state.configActJson = configActJson;
  },
  SET_CONFIG_PRODUCT: (state, configProduct) => {
    state.configProduct = configProduct;
  },
  SET_INTERVAL_SCHEDULE_LIST: (state, intervalScheduleList) => {
    state.intervalScheduleList = intervalScheduleList;
  },

  SET_IPPRODUCTINFO: (state, ipProductInfo) => {
    state.ipProductInfo = ipProductInfo;
  },
  SET_CURRENT_STEP: (state, currentStep) => {
    state.currentStep = currentStep;
  },
};
const getters = {
  currentProductSchedule: (state, getters, rootState, rootGetters) => {
    const { intervalScheduleList } = state;
    const { preOrderData, ipProductId } = rootGetters;
    const { productId: preOrderProductId } = preOrderData || {};
    const productId = preOrderProductId || ipProductId;

    const filterProdScheduleList = intervalScheduleList.filter((item) => {
      return (
        item.productId == productId &&
        item.enabled &&
        isInTime({ startTime: item.startTime, endTime: item.endTime }) &&
        item.component &&
        item.taskValue
      );
    });
    return filterProdScheduleList
      .map((item) => item.taskValue)
      .reduce((pre, cur) => Object.assign(getJson(cur), pre), {});
  },
  defaultSchedule: (state) => {
    const { intervalScheduleList } = state;

    const filterDefaultScheduleList = intervalScheduleList.filter((item) => {
      return (
        item.productId == -2 &&
        item.enabled &&
        isInTime({ startTime: item.startTime, endTime: item.endTime }) &&
        item.component &&
        item.taskValue
      );
    });
    return filterDefaultScheduleList
      .map((item) => item.taskValue)
      .reduce((pre, cur) => Object.assign(getJson(cur), pre), {});
  },
  // 混合定时任务默认配置和活动默认配置
  mixdConfigActJson: (state, getters) => {
    const { configActJson } = state;
    const { defaultSchedule } = getters;
    return mapObject(defaultSchedule, configActJson || {}, true);
  },
  curProdJson: (state, getters, rootState, rootGetters) => {
    const { configProduct } = state;
    const { currentProductSchedule, mixdConfigActJson } = getters;
    const { preOrderData, ipProductId } = rootGetters;
    const { productId: preOrderProductId } = preOrderData || {};
    const productId = preOrderProductId || ipProductId;

    const pageInfo = configProduct && configProduct.find((item) => item.productId == productId);
    document.title =
      (pageInfo && pageInfo.productId != -1 && pageInfo.productName) || '热门会员立即领';

    // console.log('mixdConfigActJson :>> ', mixdConfigActJson);

    // 混合默认配置和产品配置
    const mixActAndProd =
      pageInfo && pageInfo.productJson
        ? mapObject(getJson(pageInfo.productJson), mixdConfigActJson || {})
        : mixdConfigActJson || {};

    // 混合页面配置和定时任务
    const curdProdJson = currentProductSchedule
      ? mapObject(currentProductSchedule, mixActAndProd, true)
      : mixActAndProd;

    const { top, formInfo } = curdProdJson;
    const { textInfo: topTextInfo } = top || {};
    const { introInfo, textInfo: btnTextInfo } = formInfo || {};
    const { style: introStyle } = introInfo || {};

    // 转换头图文字单位
    if (topTextInfo && Array.isArray(topTextInfo) && topTextInfo.length) {
      topTextInfo.forEach((item) => {
        item.style.fontSize = item.style.fontSize && pxToRem(item.style.fontSize);
        item.style.marginTop = item.style.marginTop && pxToRem(item.style.marginTop);
        item.style.marginLeft = item.style.marginLeft && pxToRem(item.style.marginLeft);
      });
    }
    // 转换按钮文字单位
    if (btnTextInfo && Array.isArray(btnTextInfo) && btnTextInfo.length) {
      btnTextInfo.forEach((item) => {
        item.style.fontSize = item.style.fontSize && pxToRem(item.style.fontSize);
        item.style.marginTop = item.style.marginTop && pxToRem(item.style.marginTop);
      });
    }
    // 转换产品位置文字单位
    if (introStyle && Object.keys(introStyle).length) {
      introStyle.fontSize = introStyle.fontSize && pxToRem(introStyle.fontSize);
      introStyle.marginTop = introStyle.marginTop && pxToRem(introStyle.marginTop);
    }

    return curdProdJson;
  },
  ipProductId: (state) => {
    const { configProduct, currentRoute, ipProductInfo } = state;
    const [curProd] = configProduct || [];
    const { productId: configOneProductId } = curProd || {};
    const { productId: ipProdId } = ipProductInfo || {};
    const { query } = currentRoute || {};
    const { productId: qyProductId } = query || {};

    const resultProductId = qyProductId || ipProdId || configOneProductId;
    return resultProductId;
  },
  ipProvince: (state) => state.ipProductInfo?.province,
  ipCity: (state) => state.ipProductInfo?.city,
  stepInfo: (state, getters, rootState, rootGetters) => {
    const { actStepInfo } = state;

    const { curProdJson } = getters;
    const { formInfo } = curProdJson || {};
    const { hasStepTips, stepScheme } = formInfo || {};

    const { params } = rootGetters;
    const { actId } = params || {};

    const findActStep =
      actStepInfo.find((item) => item.actIds.includes(actId)) ||
      (hasStepTips && actStepInfo.find((item) => item.stepScheme == stepScheme));

    if (findActStep) {
      return findActStep.step;
    }
    return;
  },
};

const actions = {
  // 获取页面配置信息
  getConfigPage({ commit, state, dispatch }, params) {
    const { actId } = params;
    return new Promise((resolve) => {
      // console.log(state.confingPageInfo, actId);
      dispatch('getIntervalSchedule').finally(() => {
        if (state.confingPageInfo && state.confingPageInfo.actId == actId) {
          commit('SET_CONFIG_ACT_JSON', state.confingPageInfo.actJson || {});
          commit('SET_CONFIG_PRODUCT', state.confingPageInfo.productInfo || []);
          commit('SET_HASLOAD_CONFIG', true);
          resolve({
            actJson: state.confingPageInfo.actJson,
            productInfo: state.confingPageInfo.productInfo,
          });
        } else {
          getConfigJson(actId).then((res) => {
            if (!res) resolve();
            commit('SET_CONFIG_PAGE_INFO', res);
            commit('SET_CONFIG_ACT_JSON', res.actJson || {});
            commit('SET_CONFIG_PRODUCT', res.productJson || []);
            commit('SET_HASLOAD_CONFIG', true);
            resolve({ actJson: res.actJson, productInfo: res.productJson });
          });

          /* axios
            .get(
              `https://mgcdn.oss-cn-hangzhou.aliyuncs.com/manage_resource/${actId}.json?t=${Date.now()}`,
            )
            .then((resp) => {
              const res = resp.data;
              commit('SET_CONFIG_PAGE_INFO', res);
              commit('SET_CONFIG_ACT_JSON', res.actJson || {});
              commit('SET_CONFIG_PRODUCT', res.productJson || []);
              commit('SET_HASLOAD_CONFIG', true);
              resolve({ actJson: res.actJson, productInfo: res.productJson });
            }); */
        }
      });
    });
  },

  // 获取活动配置定时任务
  getIntervalSchedule({ commit, rootGetters }) {
    const { params } = rootGetters;
    const { actId } = params || {};
    return new Promise((resolve) => {
      getIntervalScheduleApi(actId)
        .then((res) => {
          commit('SET_INTERVAL_SCHEDULE_LIST', res || []);
        })
        .finally(() => resolve());
    });
  },

  // 根据ip推荐产品
  preOrderByIp({ commit, state, rootGetters }) {
    const { currentRoute, configProduct, configActJson, unIpLoadActExceptProvince } = state;
    const { hasInterpage } = configActJson || {};
    const [firstProduct] = configProduct || [];
    const { params } = rootGetters;
    const { actId } = params || {};
    const { productId: qyProductId } = currentRoute.query || {};

    return new Promise((resolve, reject) => {
      preOrderForIPApi({ actId })
        .then((res) => {
          const { province } = res.data || {};
          // console.log(unIpLoadActExceptProvince.includes(province));
          if (unIpLoadActExceptProvince.includes(province)) {
            commit('SET_IPPRODUCTINFO', res.data);
            try {
              console.log('change事件');
              dotCHANGE({
                act: actId,
                origin: res.data.ascription,
                productName: res.data.productName,
                actionIntro: '切换产品事件',
                queryKey: 'mobile',
                queryValue: null,
                queryKeyNd: 'productId',
                queryValueNd: res.data.productId,
                reqParams: JSON.stringify({ orderNo: res.data.orderNo }),
              });
            } catch (error) {
              console.log(error);
            }
            resolve();
          } else if (state.unIpLoadAct.includes(actId) || hasInterpage || qyProductId) {
            if (qyProductId) {
              const findQyProd = configProduct.find((item) => item.productId == qyProductId);
              commit('SET_IPPRODUCTINFO', findQyProd || {});
            } else {
              commit('SET_IPPRODUCTINFO', firstProduct || {});
            }
            resolve();
          } else {
            // console.log(2222222);
            commit('SET_IPPRODUCTINFO', res.data);

            try {
              console.log('change事件');
              dotCHANGE({
                act: actId,
                origin: res.data.ascription,
                productName: res.data.productName,
                actionIntro: '切换产品事件',
                queryKeyNd: 'productId',
                queryValueNd: res.data.productId,
                reqParams: JSON.stringify({ orderNo: res.data.orderNo }),
              });
            } catch (error) {
              console.log(error);
            }

            resolve();
          }
        })
        .catch(() => {
          reject();
        });
    });
  },
  // 获取活动说明
  getActRuleInfo({ commit }, params) {
    const { actId } = params || {};
    return new Promise((resolve) => {
      if (actId) {
        getRuleApi(actId)
          .then((res) => {
            // console.log(res);
            if (res) {
              commit('SET_RULEJSON', res);
              resolve(res);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        resolve();
      }
    });
  },

  // 设置当前手势引导
  setCurrentStep({ commit }, { currentStep }) {
    commit('SET_CURRENT_STEP', currentStep);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
