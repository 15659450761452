<template>
  <div class="cap">
    <div class="header" v-if="isShow">
      <div class="cap_logo"><Img src="/logo/logo.png" /></div>
      <div class="info" :style="{ color }">
        <h1 class="pn">{{ preOrderData.productName }}</h1>
        <p class="zifei" v-if="isZifei"><i>￥</i>{{ preOrderData.postage / 100 }}元/月</p>
      </div>
    </div>
    <!-- <slot></slot> -->
    <div class="cap_form">
      <div class="cap_input">
        <span>手机号</span
        ><input type="text" :value="params.mobile" disabled placeholder="请输入手机号码" />
      </div>
      <div class="cap_input">
        <span>验证码</span
        ><input
          type="text"
          v-model="pj.code"
          maxlength="6"
          @input="numValid"
          placeholder="请输入短信验证码"
        /><i @click="sendCode" :style="{ color }">{{ codeContent }}</i>
      </div>
    </div>
    <div class="btn_group">
      <div class="cancel btn" @click="close" :style="{ borderColor: color, color }">取消</div>
      <div class="submit btn" @click="pay" :style="{ borderColor: color, backgroundColor: color }">
        确认
      </div>
    </div>
    <p class="tips">本产品开通立即生效，次月自动续订，退订次月失效</p>
  </div>
</template>

<script>
import { getCookie } from '@/utils/cookies';
import { mapGetters } from 'vuex';
// import { getProductInfo } from "@/api/tools";
import { setCookie } from '../../utils/cookies';
export default {
  name: '',
  components: {},
  props: {
    mobile: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#f4171b',
    },
    isZifei: {
      type: Boolean,
      default: true,
    },
    isShow: {
      type: Boolean,
      default: true,
    },
    product: {
      type: Object,
      default: () => {
        return {
          productName: 'AR视频彩铃魔法会员15元包月',
          zifei: '15',
        };
      },
    },
  },
  data() {
    return {
      code: '',
      codeContent: '获取验证码',
      canSend: true,
      canPay: true,
      codeTimer: null,
      codeTime: 60,
      pj: {
        orderNo: '',
        code: '',
      },
      // reg
      reg: /^1(3|4|5|6|7|8|9)\d{9}$/,
    };
  },
  computed: {
    ...mapGetters(['params', 'isProvincial', 'preOrderData']),
  },
  watch: {},
  methods: {
    numValid() {
      this.pj.code = this.pj.code.replace(/[^0-9]/g, '');
    },
    async sendCode() {
      if (!this.canSend) {
        this.$toast('请稍后再试！');
        return;
      }
      this.canSend = false;

      this.$toast.loading('请稍后...');

      const res = await this.orderApi(this.params, this.preOrderData);
      console.log(res);

      if (res.code == 1) {
        this.countDown();
        // this.pj.orderNo = this.preOrderData.orderNo;
        this.pj.orderNo = res.data.orderNo;
      } else {
        this.$toast(res.msg);
        this.close();
      }
    },
    // 倒计时
    countDown() {
      this.codeContent = '60s';
      this.codeTimer = setInterval(() => {
        this.codeTime--;
        this.codeContent = this.codeTime + 's';
        if (this.codeTime < 0) {
          clearInterval(this.codeTimer);
          this.codeContent = '获取验证码';
          this.time = 60;
          this.canSend = true;
        }
      }, 1000);
    },
    async pay() {
      if (this.pj.code == '') {
        this.$toast('请输入验证码');
        return;
      }

      if (!this.canPay) {
        this.$toast('请稍后再试！');
        return;
      }
      this.canPay = false;

      this.$toast.loading('请稍后...');

      try {
        const res = await this.payApi(this.pj, {
          ...this.preOrderData,
          actId: this.params.actId,
        });
        if (res.code == 1) {
          this.$router.push({
            path: this.$route.path.split('index')[0] + 'success',
          });
        } else {
          this.$toast(res.msg);
          this.canPay = true;
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    close() {
      window.clearInterval(this.codeTimer);
      this.time = 60;
      this.canSend = true;
      this.codeContent = '获取验证码';
      this.pj.code = '';
      console.log('关闭');
      this.$emit('close');
    },
  },
  async created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
};
</script>
<style scoped>
.cap {
  width: 7rem;
  padding: 0.3rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.2rem;
  margin: 0 auto;
  /* background: RED; */
}
.cap .header {
  width: 100%;
  height: 0.96rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.22rem;
}
.cap .header .cap_logo {
  width: 1.28rem;
  flex-shrink: 1;
}
.cap .header .info {
  height: 100%;
  color: #ff7e28;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.cap .header .info h1 {
  font-size: 0.345rem;
  font-weight: 900;
  line-height: 1;
}
.cap .header .info p {
  width: 1.6rem;
  /* height: 0.3rem; */
  font-size: 0.26rem;
  font-weight: 900;
  border-radius: 0.3rem;
  background: #ffe8e7;
  padding: 0.03rem 0.15rem;
  box-sizing: border-box;
}
.cap .header .info p i {
  font-size: 0.16rem;
}
.cap .cap_form {
  width: 100%;
}
.cap .cap_form .cap_input {
  width: 100%;
  height: 0.75rem;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.2rem;
  box-sizing: border-box;
  border-radius: 0.08rem;
  position: relative;
  margin-bottom: 0.32rem;
}
.cap .cap_form .cap_input input {
  width: calc(100% - 1.1rem);
  background: #efefef;
  font-size: 0.26rem;
}
.cap .cap_form .cap_input input::-webkit-input-placeholder {
  color: #ceccce;
}
.cap .cap_form .cap_input span {
  color: #555;
  font-weight: 900;
  font-size: 0.3rem;
  margin-right: 0.2rem;
}
.cap .cap_form .cap_input i {
  position: absolute;
  right: 0.2rem;
  color: #ff7e28;
  font-size: 0.3rem;
}
.cap .btn_group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cap .btn_group .btn {
  width: 48%;
  height: 0.7rem;
  border-radius: 0.35rem;
  /* background: #ff7e28; */
  border: 1px solid #ff7e28;
  text-align: center;
  line-height: 0.7rem;
  font-size: 0.3rem;
  color: #ff7e28;
}
.cap .btn_group .submit {
  background: #ff7e28;
  color: #fff;
}
.cap > p {
  width: 100%;
  text-align: center;
  color: #888;
  font-size: 0.22rem;
  margin-top: 0.1rem;
}
</style>
<style>
/* html,
body,
#app {
  width: 100%;
  height: 100%;
} */
</style>
