<template>
  <!--  v-show="showFixedBtn && formInfo.btnFloat" -->
  <div
    class="fixed-btn"
    :class="{ 'btn-animate': formInfo.btnAnimate }"
    v-show="showFixedBtn && formInfo.btnFloat"
    @click="toTop"
  >
    <!-- <img src="" alt="" /> -->
    <template v-if="btnImgType == 'object'">
      <img v-for="(item, index) in formInfo.btnImg" :key="index" :src="item" alt="按钮" />
    </template>

    <img v-else-if="formInfo.btnImg" :src="formInfo.btnImg" alt="按钮" />
  </div>
</template>

<script>
import _ from 'lodash';
import { pageScrollHandle } from '@/utils/tools';
import { mapGetters } from 'vuex';
export default {
  name: '',
  data() {
    return {
      showFixedBtn: false,
      appPaddingBottom: 0,
    };
  },
  computed: {
    ...mapGetters(['curProdJson']),
    formInfo: function () {
      // console.log(this.configActJson);
      return this.curProdJson?.formInfo || {};
    },
    btnImgType: function () {
      return typeof this.formInfo.btnImg;
    },
  },
  watch: {
    appPaddingBottom(newVal) {
      this.$parent.$el.style.paddingBottom = newVal + 'px';
    },
  },
  methods: {
    toTop() {
      pageScrollHandle(document.querySelector('#form-wrap'), -200);
    },
    scrollHandle: function () {
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const eleBtn = this.$parent.$refs['routerView']?.$refs['xForm']?.$refs['btn'];
      if (eleBtn) {
        const { top, height } = eleBtn.getBoundingClientRect();
        if (top < -height) {
          // 展示悬浮按钮
          // console.log("展示悬浮按钮");
          this.showFixedBtn = true;
          this.formInfo.btnFloat && (this.appPaddingBottom = height + 10);
        } else {
          // console.log("隐藏悬浮按钮");
          this.showFixedBtn = false;
        }
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener(
      'scroll',
      _.throttle(this.scrollHandle, 300, { leading: false, trailing: true }),
    );
  },
};
</script>

<style scoped lang="scss">
.fixed-btn {
  width: 6rem;
  position: fixed;
  bottom: 10px;
  left: 50%;
  margin-left: -3rem;
  // transform: translateX(-3rem);
}
</style>
