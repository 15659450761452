<template>
  <div v-if="actTopInfo.hasMobileSwiper">
    <Horizontal :visible="seedSchemeValue == 1" />
    <Vertical :visible="seedSchemeValue == 2" />
    <Vertical :visible="seedSchemeValue == 3 && isSendCode" />
  </div>
</template>

<script>
import Horizontal from './horizontal.vue';
import Vertical from './vertical.vue';
import { mapGetters } from 'vuex';
export default {
  name: '',
  components: { Horizontal, Vertical },
  data() {
    return {
      seedScheme1ActIds: [],
      seedScheme2ActIds: [1270, 1310, 1309, 1341, 1342, 1344, 1361],
      seedScheme3ActIds: [
        1244, 1247, 1265, 1269, 1281, 1283, 1290, 1286, 1300, 1313, 1323, 1327, 1333, 1315, 1374,
        1378, 1379, 1380, 1384, 1366, 1367, 1368,
      ],
    };
  },
  computed: {
    ...mapGetters([
      'curProdJson',
      'configActJson',
      'ipProductId',
      'productId',
      'hasLoadConfig',
      'params',
      'currentRoute',
      'stepInfo',
      'isSendCode',
      'mixdConfigActJson',
    ]),
    actTopInfo: function () {
      const { top } = this.mixdConfigActJson || {};
      return top || {};
    },
    mobileSwiperByActId: function () {
      if (this.seedScheme2ActIds.includes(this.params.actId)) {
        return 2;
      } else if (this.seedScheme3ActIds.includes(this.params.actId)) {
        return 3;
      }
      return 1;
    },
    seedSchemeValue: function () {
      const { top } = this.curProdJson || {};
      const { seedScheme } = top || {};
      console.log('seedScheme :>> ', seedScheme);
      return seedScheme || this.mobileSwiperByActId;
    },
  },
};
</script>
<style scoped lang="scss"></style>
