import { post, get } from '@/api/request';
import { dotPREORDER } from '@/api/dot';

/**
 * 根据防窥码获取当前活动信息
 * @returns
 */
export const getActIdByCode = (params) => get('/productAct/getActCheckBySaltValue', params);

/**
 * 预下单接口
 * @param {*} data
 * @returns
 */
export const preOrderApi = (data) => {
  return new Promise((resolve, reject) => {
    try {
      dotPREORDER({
        act: data.actId,
        actionIntro: `预下单事件`,
        queryKey: 'mobile',
        queryValue: data.mobile,
        reqParams: JSON.stringify(data),
      });
    } catch (error) {
      console.log(error);
    }
    post('/order/preloadOrder', data)
      .then((res) => {
        try {
          dotPREORDER({
            act: data.actId,
            actionIntro: `预下单完成`,
            queryKey: 'mobile',
            queryValue: data.mobile,
            reqParams: JSON.stringify(data),
            respParams: JSON.stringify({
              code: res.code,
              msg: res.msg,
              data: res.data,
            }),
          });
        } catch (error) {
          console.log(error);
        }
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * 预下单接口（新流程）
 * @param {*} data
 * @returns
 */
export const newPreOrderApi = (data) => {
  return new Promise((resolve, reject) => {
    try {
      dotPREORDER({
        act: data.actId,
        actionIntro: `预下单事件`,
        queryKey: 'mobile',
        queryValue: data.mobile,
        reqParams: JSON.stringify(data),
      });
    } catch (error) {
      console.log(error);
    }
    post('/order/orderPreCheck/V2', data)
      .then((res) => {
        try {
          dotPREORDER({
            act: data.actId,
            actionIntro: `预下单完成`,
            queryKey: 'mobile',
            queryValue: data.mobile,
            reqParams: JSON.stringify(data),
            respParams: JSON.stringify({
              code: res.code,
              msg: res.msg,
              data: res.data,
            }),
          });
        } catch (error) {
          console.log(error);
        }
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * 下单接口
 * @param {*} data
 * @returns
 */
export const orderApi = (data) => post('/order/addOrder', data);

/**
 * 支付接口
 * @param {*} data
 * @returns
 */
export function payApi(data) {
  return post('/order/pay', data, {
    timeout: 15000,
  });
}

/**
 * 订单通知
 * @param {*} data
 * @returns
 */
export function notifyAPi(data) {
  return get('/order/notify', data);
}

/**
 * 预下单临时接口
 * @param {*} data
 * @returns
 */
export function preOrderTempApi(data) {
  return post('/order/tempPreloadOrder', data);
}

/**
 * 下单临时接口
 * @param {*} data
 * @returns
 */
export function orderTempApi(data) {
  return post('/order/tempAddOrder', data);
}

/**
 * 获取打开活动页面
 * @returns
 */
export async function getOpenAct(actId) {
  return get('/productAct/getActCheck', { actId });
}

/**
 * 根据ip获取当前产品
 * @param {*} data
 * @returns
 */
export function preOrderForIPApi(data) {
  return post('/order/preloadOrderForIp', data);
}

/**
 * 字节一键获取手机号码
 * @param {*} data
 * @returns
 */
export function getBytePhoneApi(data) {
  return post('/wifi/phone/token_validate', data);
}
