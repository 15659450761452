import { routes } from '@/router';

const SUCCESS = require('@/pages/success/index.vue').default;
const TEMPLATE1 = require('@/pages/template1/index.vue').default;

const state = {
  isAddRoute: false,
};

const mutations = {
  SET_ISADDROUTE: (state, isAddRoute) => {
    state.isAddRoute = isAddRoute;
  },
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  generateRoutes({ commit }, params) {
    return new Promise((resolve) => {
      // console.log(params);
      // console.log(routes);
      const { path } = params || {};
      // console.log(hasRoute(path));
      if (hasRoute(path)) resolve();
      if (path.includes('index')) {
        resolve({
          path,
          component: TEMPLATE1,
        });
      } else if (path.includes('success')) {
        resolve({
          path,
          component: SUCCESS,
        });
      }
      resolve();
    });
  },
};

function hasRoute(path) {
  return routes.some((item) => item.path == path || item.alias?.includes(path));
  /* return routes.some((item) => {
		if (item.alias) {
			if (item.path == path || item.alias.includes(path)) return true;
		} else {
			if (item.path == path) return true;
		}
		return false;
	}); */
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
