<template>
  <div class="policy-wrap" v-if="show">
    <div class="policy">
      <div class="title">
        <img
          @click="closePolicy"
          src="https://sgact.oss-cn-hangzhou.aliyuncs.com/actStatic/img/circle-close.png"
          alt="关闭"
        />
      </div>
      <div class="policy-content">
        <h1>个人信息授权与保护声明</h1>
        <p>尊敬的客户：</p>
        <p><br /></p>
        <p>欢迎您使用我们的产品和服务！</p>
        <p><br /></p>
        <p>
          我们深知个人信息的重要性，也致力于保护每个用户的个人信息。您的信赖对我们至关重要，我们将严格遵守相关法律法规要求采取相应的安全保护措施，使您的个人信息安全可控。基于此，我们制定了《隐私保护协议》，帮助您充分了解您在使用我们业务和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。
        </p>
        <p><br /></p>
        <p>
          在您开始使用我们的业务和服务前，请您务必先仔细阅读和理解本政策，确保您充分理解和同意之后再开始使用。您点击同意隐私协议或者我们认可的其他方式使用业务和服务时即视为您完全同意本协议的全部内容。
        </p>
        <p><br /></p>
        <p>
          当本协议的任何一条被视为废止、宣告无效或因任何理由不可执行，该条应视为可分的且不影响其他条款的有效性和可执行性。
        </p>
        <p><br /></p>
        <p>一、隐私政策</p>
        <p><br /></p>
        <p>
          本隐私政策旨在帮助您了解我们会收集哪些数据、为什么收集这些数据、会利用这些数据做什么以及我们如何保护这些数据。
        </p>
        <p><br /></p>
        <p>
          在您订购业务之前，请您务必抽出时间认真阅读本政策，以保障您的个人权利及保护您的个人信息。
        </p>
        <p><br /></p>
        <p>(一)．我们如何收集和使用您的个人信息</p>
        <p><br /></p>
        <p>我们仅处于本政策所述的以下目的，收集和使用您的个人信息：</p>
        <p><br /></p>
        <p>1. 业务一：订购与支付</p>
        <p>
          &nbsp; -
          订购业务时，您需提供以下信息：您的手机号、手机短信验证码，您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。
        </p>
        <p>
          &nbsp;
          -我们会保留订单记录、支付记录，这些信息会存储于我们的服务器中。以上信息为您能够享受我司产品服务所必须的信息，如您不同意我们记录前述信息，我们将无法为您提供产品购买和使用服务。上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会征得您的授权同意。
        </p>
        <p><br /></p>
        <p>2. 业务二：用户画像、个性化推荐、定向推送</p>
        <p>
          &nbsp; -
          您使用本业务时，我们会记录您的权益领取操作日志，包括但不限于搜索记录、登录记录、使用时长、浏览记录、阅读记录、购买记录、收藏记录等。
        </p>
        <p>
          &nbsp; -
          我们会基于前述信息以及您的收藏数据对您的偏好、使用习惯特征分析和用户画像，以便为您提供更适合您的定制化服务，例如向您展现或推荐相关程度更高的搜索结果、产品内容或者广告/推广信息结果。
        </p>
        <p><br /></p>
        <p>(二)我们如何共享、转让、公开披露您的个人信息</p>
        <p><br /></p>
        <p>1. 共享</p>
        <p>
          &nbsp; - 我们不会与除我司以外其他任何公司、组织和个人分享您的个人信息，但以下情况除外：
        </p>
        <p>
          &nbsp; &nbsp; -
          在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
        </p>
        <p>
          &nbsp; &nbsp; -
          我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
        </p>
        <p>
          &nbsp; &nbsp; -
          为订立、履行您作为一方当事人的合同所必需的情况下的共享：您通过我司权益领取平台选择领取的权益服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关产品或服务的提供者，以实现您的交易、配送和售后服务要求。
        </p>
        <p>
          &nbsp; &nbsp; -
          与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
        </p>
        <p>&nbsp; - 目前，我们的授权合作伙伴包括以下几大类型：</p>
        <p>
          &nbsp; &nbsp; -
          广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴分享。但我们可能会将您的匿名化的用户画像与广告、分析服务类的授权合作伙伴共享，以帮助其在不识别您的个人的前提下提升广告有效触达率。
        </p>
        <p>
          &nbsp; &nbsp; -
          供应商、服务提供商和其他合作伙伴。我们将信息发送给在全球范围内支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。
        </p>
        <p>
          &nbsp; -
          对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
        </p>
        <p><br /></p>
        <p>2. 转让</p>
        <p>&nbsp; - 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p>
          &nbsp; &nbsp; -
          在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
        </p>
        <p>
          &nbsp; &nbsp; -
          在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p><br /></p>
        <p>3. 公开披露</p>
        <p>&nbsp; - 我们仅会在以下情况下，公开披露您的个人信息：</p>
        <p>&nbsp; &nbsp; - 获得您明确同意后；</p>
        <p>
          &nbsp; &nbsp; -
          基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        </p>
        <p><br /></p>
        <p>4. 共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
        <p>&nbsp; - 以下情形中，共享、转让、公开披露个人信息时无需事先征得您的授权同意：</p>
        <p>&nbsp; &nbsp; - 与国家安全、国防安全有关的；</p>
        <p>&nbsp; &nbsp; - 与公共安全、公共卫生、重大公共利益有关的；</p>
        <p>&nbsp; &nbsp; - 与犯罪侦查、起诉、审判和判决执行等有关的；</p>
        <p>&nbsp; &nbsp; - 出于维护您或其他个人的生命、财产等重大合法权益但又难得到本人同意的；</p>
        <p>&nbsp; &nbsp; - 您自行向社会公众公开的个人信息；</p>
        <p>
          &nbsp; &nbsp; -
          从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
        </p>
        <p><br /></p>
        <p>5. 我们如何保护您的个人信息</p>
        <p>
          &nbsp; - (1)
          我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
        </p>
        <p>
          &nbsp; - (2)
          保存期限：我们仅在中国大陆地区存放您的个人信息。我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的且法律规定的最短期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        </p>
        <p>
          &nbsp; - (3)
          互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
        </p>
        <p>
          &nbsp; - (4)
          互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        </p>
        <p>
          &nbsp; - (5)
          在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        </p>
        <p><br /></p>
        <p>6. 我们如何存储您的个人信息</p>
        <p>&nbsp; - (1) 个人信息的存储</p>
        <p>
          &nbsp; &nbsp; -
          原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。同时只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；《教育部等六部门关于规范校外线上培训的实施意见》规定，用户行为日志须留存1年以上。就我们服务所涉及的用户行为日志，我们将进行匿名化处理。我们判断前述期限的标准包括：
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; - 1.1
          完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；
        </p>
        <p>&nbsp; &nbsp; &nbsp; - 1.2 保证我们为您提供服务的安全和质量；</p>
        <p>&nbsp; &nbsp; &nbsp; - 1.3 您是否同意更长的留存期间；</p>
        <p>&nbsp; &nbsp; &nbsp; - 1.4 是否存在保留期限的其他特别约定。</p>
        <p>
          &nbsp; &nbsp; -
          在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理（如您为未成年人，在您的个人信息超出保留期间后，平台会按照相关法律法规要求对您的个人信息进行相应处理）。如因特殊情形无法按照相关要求删除的，我们将对该部分事项向您进行说明。
        </p>
        <p><br /></p>
        <p>二、个人信息在全球范围的转移</p>
        <p><br /></p>
        <p>
          目前我们不会跨境传输或存储您的个人信息。将来如需跨境传输或存储的，我们会向您告知信息出境的目的、接收方、安全保障措施和安全风险，并征得您的同意。
        </p>
        <p><br /></p>
        <p>1．未成年人保护</p>
        <p><br /></p>
        <p>
          我们重视未成年人的个人信息保护，如果您为十八周岁以下的未成年人，请在您的监护人指导下仔细阅读本隐私政策，并在征得您的监护人同意的前提下使用我们的服务及提交您的个人信息。如您的监护人不同意本隐私政策，您应立即停止使用我们的服务并拒绝提供个人信息。
        </p>
        <p><br /></p>
        <p>
          如果您是未满十四周岁的未成年人，请您通知您的监护人共同阅读并接受我们的《用户隐私政策》并在您订购、使用我司业务时，寻求您的监护人的同意和指导。
        </p>
        <p><br /></p>
        <p>
          对于监护人同意而收集的儿童个人信息，我们仅会在法律法规允许、监护人明确同意或保护儿童用户的范围内使用或披露该信息。对于未经监护人同意而儿童用户提供的个人信息，在接到其监护人的通知后，我们会尽快删除相关信息。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    host: {
      type: String,
      default: 'xy',
    },
  },
  data() {
    return {
      show_policy: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    closePolicy() {
      this.$emit('close', false);
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeDestroy() {},
};
</script>
<style scoped>
.policy-wrap {
  position: fixed;
  inset: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
.policy-wrap .policy {
  width: 90%;
  height: 90%;
  background: #fff;

  font-size: 0.24rem;
  border-radius: 0.2rem;
  text-align: justify;
  overflow: hidden;
}
.policy-wrap .policy .title {
  width: 100%;
  height: 1rem;
  background: #ffa100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.policy-wrap .policy .title img {
  width: 0.8rem;
  margin-right: 0.2rem;
}
.policy-wrap .policy .policy-content {
  width: 100%;
  height: calc(100% - 1rem);
  overflow-y: auto;
  padding: 0.2rem;
  box-sizing: border-box;
  -moz-user-select: none; /*火狐*/

  -webkit-user-select: none; /*webkit浏览器*/

  -ms-user-select: none; /*IE10*/

  -khtml-user-select: none; /*早期浏览器*/

  user-select: none;
}
.policy-wrap .policy h1 {
  text-align: center;
  /* background: #ffa100; */
  height: 1rem;
  line-height: 1rem;
  font-weight: 900;

  margin-bottom: 0.3rem;
}
.policy-wrap .policy h2 {
  font-size: 0.28rem;
  margin: 0.15rem auto;
  font-weight: 900;
}

.policy-wrap .policy h3 {
  font-size: 0.28rem;
  margin: 0.15rem auto;
  font-weight: 900;
}
.policy-wrap .policy .blod {
  font-weight: 900;
  font-size: 0.24rem;
}
</style>
