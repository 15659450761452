var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container",style:({
    '--bgColor': _vm.curProdJson.bgColor,
    '--color': _vm.curProdJson.color || '#fff',
    backgroundColor: _vm.curProdJson.bgColor,
    color: _vm.curProdJson.color || '#fff',
  })},[_c('Module',[_c('div',{staticClass:"img-list"},[_vm._l((_vm.topInfo.topImg),function(item,index){return _c('img',{key:index,attrs:{"src":item,"alt":""}})}),_vm._l((_vm.topTextInfo),function(item,index){return _c('p',{key:item.text + index,style:({
          width: item.style.marginLeft ? 'auto' : '100%',
          fontSize: item.style.fontSize,
          color: item.style.color,
          top: item.style.marginTop,
          left: item.style.marginLeft,
          opacity: item.style.opacity,
          textDecoration: item.style.textDecoration,
          fontWeight: item.style.fontWeight,
        })},[(item.show)?[_vm._v(_vm._s(item.text))]:_vm._e()],2)})],2)]),_c('MobileSwiper'),(_vm.createFormByte)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.byteFormVisible),expression:"byteFormVisible"}],attrs:{"id":"ydrzCustomControls"}}):_vm._e(),_c('x-Form',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasLoadConfig && !_vm.byteFormVisible),expression:"hasLoadConfig && !byteFormVisible"}],ref:"xForm",attrs:{"byteMobile":_vm.byteMobile}},[_vm._l((_vm.stepInfo),function(item){return _c('div',{key:item.name,staticClass:"step",style:(item.style),attrs:{"slot":item.name},slot:item.name},[(_vm.currentStep == item.stepName)?_c('img',{attrs:{"src":item.img,"alt":""}}):_vm._e()])})],2),(_vm.productId != -1)?_c('Module',{staticClass:"wel"},[_c('div',{staticClass:"img-list"},_vm._l((_vm.welInfo.welImg || []),function(item,index){return _c('img',{key:index,attrs:{"src":item,"alt":""}})}),0)]):_vm._e(),(_vm.visibleRule)?_c('Rule'):_vm._e(),(_vm.createDialogByte)?_c('ByteMobile',{attrs:{"visible":_vm.byteDialogVisible},on:{"update:visible":function($event){_vm.byteDialogVisible=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }