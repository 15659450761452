import { createScript } from '@/utils/tools';
const state = {
  agentUrl: 'https://wap.hn.10086.cn/agent/pagecode-agent.js',
  apiUrl: 'https://wap.hn.10086.cn/collector/terminal.api',
  appId: 'ITFHZXLK',
  appToken: 'izkcQWa2hz1725334365801',

  // 添加需要插码的湖南移动产品，actId：产品所在的活动编号
  productInfo: [
    {
      productId: 475,
      actId: [1200],
      info: {
        goodsId: '99865410',
        goodsCode: '99865410',
        goodsName: '湖南移动随心选20G流量版',
      },
    },
    {
      productId: 274,
      actId: [1200, 1230],
      info: {
        goodsId: '99693751',
        goodsCode: '99693751',
        goodsName: '全网追剧流量包',
      },
    },
    {
      productId: 202,
      actId: [],
      info: {
        goodsId: '99666464',
        goodsCode: '99666464',
        goodsName: '随心看会员',
      },
    },
    {
      productId: 325,
      actId: [1200],
      info: {
        goodsId: '99861784',
        goodsCode: '99861784',
        goodsName: '25G权益流量包(每天惠版)合约型月费（12个月合约）',
      },
    },
  ],
};

const mutations = {};

const actions = {
  initScript({ state, dispatch }, params) {
    const { agentUrl, apiUrl, appId, appToken, productInfo } = state;
    const { actId } = params;
    const findProduct = productInfo.filter((item) => item.actId.includes(actId));
    if (!findProduct.length) return;
    window._pagecode = {
      initParams: {
        appId: appId,
        appToken: appToken,
        apiUrl: apiUrl,
      },
      /* appId: appId,
      appToken: appToken,
      apiUrl: apiUrl, */
      init: function (option) {
        // console.log(window._vops);
        if (window._vops) {
          window._vops.init(option);
        } else {
          console.log(' _vops not exit or is not a function.');
        }
      },
      push: function (option) {
        if (window._vops) {
          window._vops.push(option);
        } else {
          console.log(' _vops not exit or is not a function.');
        }
      },
      pushSyn: function (option) {
        if (window._vops) {
          window._vops.pushSyn(option);
        } else {
          console.log(' _vops not exit or is not a function.');
        }
      },
    };

    const dHead = document.head;
    const oTitle = dHead.getElementsByTagName('title')[0];
    const oMeta1 = document.createElement('meta');
    oMeta1.id = 'v.channel';
    oMeta1.setAttribute('content', 'HANGZHOUXIANGYU');
    dHead.insertBefore(oMeta1, oTitle);
    const oMeta2 = document.createElement('meta');
    oMeta2.id = 'v.bizHandlerPage';
    oMeta2.setAttribute('content', 'true');
    dHead.insertBefore(oMeta2, oTitle);
    const oSpan = document.createElement('span');
    oSpan.id = 'vops_stat_icon_1254131450';
    document.body.appendChild(oSpan);

    return new Promise((resolve) => {
      createScript(agentUrl).then(() => {
        dispatch('track_INDEX', params);
        resolve();
      });
    });
  },
  track_INDEX({ state }, params) {
    const { productInfo } = state;
    const { actId } = params || {};
    const findProduct = productInfo.filter((item) => item.actId.includes(actId));
    try {
      if (findProduct.length) {
        findProduct.forEach((item) => {
          window._pagecode.push([
            '流量包',
            '首页',
            { stepNo: '1', scene: '进入办理落地页', ...item.info },
          ]);
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  track_LJBL({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId === productId) || '';
    try {
      if (findProduct) {
        window._pagecode.push([
          '流量包',
          '立即办理',
          { stepNo: '20', scene: '场景一', ...findProduct.info },
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  },
  track_GETCODE({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId == productId) || '';
    if (findProduct)
      window._pagecode.push(['流量包', '获取验证码', { stepNo: '30', ...findProduct.info }]);
  },
  track_GETCODE_SUCCESS({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId == productId) || '';
    if (findProduct)
      window._pagecode.push(['流量包', '获取验证码成功', { stepNo: '31', ...findProduct.info }]);
  },
  track_GETCODE_FAILE({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId == productId) || '';
    if (findProduct)
      window._pagecode.push(['流量包', '获取验证码失败', { stepNo: '-31', ...findProduct.info }]);
  },
  track_INPUTCODE({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData, payParams } = getters;
    const { productId } = preOrderData || {};
    const { code } = payParams || {};
    const findProduct = productInfo.find((item) => item.productId == productId) || '';
    if (findProduct)
      window._pagecode.push([
        '流量包',
        '输入验证码',
        { stepNo: '32', smsCode: code, ...findProduct.info },
      ]);
  },
  track_QRBL({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId === productId) || '';
    try {
      if (findProduct)
        window._pagecode.push(['流量包', '确认办理', { stepNo: '21', ...findProduct.info }]);
    } catch (error) {
      console.log(error);
    }
  },
  track_QXBL({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId === productId) || '';
    try {
      if (findProduct)
        window._pagecode.push(['流量包', '取消办理', { stepNo: '-21', ...findProduct.info }]);
    } catch (error) {
      console.log(error);
    }
  },

  track_BUSINESSRULE_VALID({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId == productId) || '';
    if (findProduct)
      window._pagecode.push([
        '流量包',
        '业务规则校验',
        { stepNo: '40', scene: '办理资格校验', ...findProduct.info },
      ]);
  },
  track_BUSINESSRULE_SUCCESS({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId == productId) || '';
    if (findProduct)
      window._pagecode.push([
        '流量包',
        '业务规则校验成功',
        { stepNo: '41', scene: '办理资格校验', ...findProduct.info },
      ]);
  },
  track_BUSINESSRULE_FAILE({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId == productId) || '';
    if (findProduct)
      window._pagecode.push([
        '流量包',
        '业务规则校验失败',
        { stepNo: '-41', scene: '办理资格校验', ...findProduct.info },
      ]);
  },
  track_ORDER_SUCCESS({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId === productId) || '';
    try {
      if (findProduct)
        window._pagecode.push(['流量包', '办理成功', { stepNo: '99', ...findProduct.info }]);
    } catch (error) {
      console.log(error);
    }
  },
  track_ORDER_FAILE({ state, getters }) {
    const { productInfo } = state;
    const { preOrderData } = getters;
    const { productId } = preOrderData || {};
    const findProduct = productInfo.find((item) => item.productId === productId) || '';
    try {
      if (findProduct)
        window._pagecode.push(['流量包', '办理失败', { stepNo: '-99', ...findProduct.info }]);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
