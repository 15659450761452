<template>
  <div id="cqFanZha" class="cq-fanzha-imgs" ref="fanZhaImgs" v-if="hasFanZha">
    <!-- 收起 -->
    <div class="pack-up" @click="packUpHandle">反诈科普与实名登记说明<i></i></div>
    <div v-show="!isFold">
      <img v-for="(item, index) in cqFanZhaImgs" :key="index" v-lazy="item" alt="" />
      <div class="pack-down" @click="isFold = !isFold">收起<i></i></div>
    </div>
  </div>
</template>

<script>
import { pageScrollHandle } from '@/utils/tools';
import { mapGetters } from 'vuex';
export default {
  name: '',
  props: {
    imgList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFold: true,

      productIds: ['527', '526', '456', '419', '297', '296', '122', '331'],
    };
  },
  computed: {
    ...mapGetters(['productId', 'ruleJson']),
    hasFanZha: function () {
      return this.productIds.includes(this.productId?.toString());
    },
    cqFanZhaImgs: function () {
      return this.ruleJson.cqFanZha || [];
    },
  },
  methods: {
    packUpHandle() {
      this.isFold = !this.isFold;
      this.$nextTick(() => {
        pageScrollHandle(document.querySelector('#cqFanZha'));
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.cq-fanzha-imgs {
  width: 100%;
  background-color: #f8f8f8;
  color: #333;
  font-size: 0.28rem;
  .pack-up,
  .pack-down {
    line-height: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: '';
      display: block;
      width: 1.5rem;
      height: 2px;
      background-image: linear-gradient(to left, #aaa, transparent);
      margin-right: 10px;
    }
    &::after {
      content: '';
      display: block;
      width: 1.5rem;
      height: 2px;
      background-image: linear-gradient(to right, #aaa, transparent);
      margin-left: 10px;
    }
    i {
      width: 8px;
      height: 8px;
      transform: translateY(-2px) rotateZ(45deg);
      // border: 2px solid #333;
      border-bottom: 2px solid #666;
      border-right: 2px solid #666;
      margin-left: 5px;
    }
  }
  .pack-down {
    i {
      border-top: 2px solid #666;
      border-left: 2px solid #666;
      border-bottom: none;
      border-right: none;
      transform: translateY(2px) rotateZ(45deg);
    }
  }
}
</style>
