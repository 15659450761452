<template>
  <GdydPolicy
    :show.sync="show"
    v-if="gdydPolicyProd.includes(Number(productId))"
    @close="close"
  ></GdydPolicy>
  <WoreadPolicy
    :show.sync="show"
    :origin="origin"
    v-else-if="woreadPolicyProd.includes(Number(productId))"
    @close="close"
  ></WoreadPolicy>
  <GdltPolicy
    :show.sync="show"
    :origin="origin"
    v-else-if="gdltPolicyProd.includes(Number(productId))"
    @close="close"
  ></GdltPolicy>
  <DefaultPolicy :show.sync="show" v-else @close="close"></DefaultPolicy>
</template>

<script>
import { mapGetters } from 'vuex';
import DefaultPolicy from './default.vue';
import GdydPolicy from './gdyd.vue';
import WoreadPolicy from './woread.vue';
import GdltPolicy from './gdlt.vue';
export default {
  name: '',
  components: { DefaultPolicy, GdydPolicy, WoreadPolicy, GdltPolicy },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    origin: String,
  },
  data() {
    return {
      host: '',
      gdltPolicyProd: [676, 675, 496, 486, 477, 476, 444, 443, 345, 105],
      gdydPolicyProd: [],
      woreadPolicyProd: [],
      showPolicy: false,
    };
  },
  computed: {
    ...mapGetters(['productId']),
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    getHost() {
      const host = window.location.host;
      if (host.includes('ilives')) {
        this.host = 'xy';
      }
      if (host.includes('allunion')) {
        this.host = 'wwhy';
      }
      if (host.includes('baoqiliang')) {
        this.host = 'bql';
      }
      if (host.includes('dmsxx')) {
        this.host = 'xt';
      }
      if (host.includes('ejnjyc')) {
        this.host = 'dyc';
      }
      if (host.includes('everwin')) {
        this.host = 'ls';
        if (host.includes('xy01')) {
          this.host = 'hs';
        }
      }
      if (host.includes('junjue888')) {
        this.host = 'junjue';
      }

      if (host.includes('jlidushu')) {
        this.host = 'vivo';
      }
      if (host.includes('coo1read')) {
        this.host = 'ky';
      }
      if (host.includes('junjing998')) {
        this.host = 'yt';
      }
      if (host.includes('laidianbanlv') || host.includes('rk82.cn')) {
        this.host = 'xiaowai';
      }
      if (host.includes('gzcsnt')) {
        this.host = 'cs'; //广州创搜网络科技有限公司
      }
      if (host.includes('ylqiliang')) {
        this.host = 'yl'; //广州创搜网络科技有限公司
      }
    },
  },
  created() {
    this.getHost();
  },
};
</script>

<style scoped lang="scss"></style>
